import "./MobileLandscape.css";
import "./modal-components/ModalDialog.css";
import { useState, useLayoutEffect, useEffect, Fragment } from "react";
import Modal from "./modal-components/ModalDialog";
import MobileMode from "./RightSection/MobileMode";
import { useActions } from "../../hooks/use-actions";
import MobileNavbar from "./LeftSection/MobileNavbar";
import ShowModal from "./modal-components/ImageModal";
import MobileLogo from "../SvgIcons/MobileSvgIcons/MobileLogo";
import MobileSlider from "./FooterSection/MobileSlider";
import MobileCompass from "./RightSection/mobileCompass";
import NavbarHandler from "../leftsection/NavbarHandler";
import MobileRoofView from "./LeftSection/MobileRoofView";
import MobileAutoMode from "./FooterSection/MobileAutoMode";
import MobileComponent from "./LeftSection/MobileComponent";
import { connectToWebRTC, roofViewMode } from "../../services/navbarServices";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { StateActionTypes } from "../ComponentStateService/ItemTypes";
import MobileDescriptionModal from "./LeftSection/ComponentDescriptionModal/MobileDescriptionModal";
import { ThemeMode } from "../rightsection/ThemeMode";
import ComponentDescriptionModalToggle from "../Common/ComponentDescriptionModalToggle";
import LeftSideMobileContent from "./LeftSection/LeftSideMobileContent";
import UseToggle from "../UseToggle";
import { Loader } from "../MainScreen";
import HelpSectionTooltip from "../Common/HelpSectionTooltip";
import ToolTextPermissionBox from "../Common/ToolTextPermissionBox";
import { mobileSteps } from "../Common/ToolTipSteps";
import { componentUnSelection } from "../../services/componentServices";
import screenfull from "screenfull";
import HomeComponentHandler from "../HomeComponentHandler";

const MobileLandscape = ({ isFullScreen, handleDisableMouseInteraction }: any) => {
    const { dispatch } = NavbarHandler();

    const { isPortrait } = useMobileOrientation();
    const { HandleRoomAction, setCameraIndex, selectedComponentItem } = useActions();
    const [isShow, setIsShow] = useState(true);
    const [showMobileHelpSection, setShowMobileHelpSection] = useState(true);
    const [startMobileHelpTutorial, setStartMobileHelpTutorial] = useState(false)
    const [isMatched, setIsMatched] = useState<boolean>(false);
    const { changeCurrentMode } = useTypedSelector(({ mode }) => mode)
    const { selectedComponentRoom } = useTypedSelector(({ component }) => component);
    const { setDefaultMovingRoom } = useTypedSelector(({ footernavbar }) => footernavbar);
    const { setIsFullScreen } = HomeComponentHandler();
 
    const handleStartEmulator = () => {
        connectToWebRTC();
        setIsShow(false)
        document.body.requestFullscreen()
    }

    useLayoutEffect(() => {
        // Here Updating Room and CameraIndex Initialy When backend sends
        if (setDefaultMovingRoom.Room !== "None") {
            HandleRoomAction(setDefaultMovingRoom.Room)
            setCameraIndex(setDefaultMovingRoom.CameraIndex)
            dispatch({ type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: setDefaultMovingRoom.Type })
        }
    }, [setDefaultMovingRoom])

    const [descriptionModalToggle, setDescriptionModalToggle] = useState(false);
    const [isRoofViewOpen, setIsRoofViewOpen] = useState(false);

    const handleDescriptionToggle = () => {
        setDescriptionModalToggle((prev) => !prev);
    };

    const {
        isToggleOpen,
        targetCurrentElementRef,
        handleUpdateToggleState
    } = UseToggle([false, false]);

    const closeActiveUi = () => {
        componentUnSelection(selectedComponentRoom);
        selectedComponentItem("")
        if (screenfull.isEnabled) {
            screenfull.exit()
        }
        setIsRoofViewOpen(false)
        roofViewMode(true)
        setIsFullScreen(false)
    }

    const handleRoofViewCallBack = (value: any) => {
        setIsRoofViewOpen(value)
    }

    return (
        <>
        <div>
            <ShowModal isPortrait={isPortrait}>
                {isPortrait && <img src="/Portrait_rotate/rotate_mobile.gif" alt="gif" />}
            </ShowModal>
            {isShow ?
                <Modal
                    isPortrait={isPortrait}
                    handleStartEmulator={handleStartEmulator}
                    className={`modal_outer ${!isMatched && 'disable-launch-btn'}`}
                    isMatched={isMatched}
                    setIsMatched={setIsMatched}
                >
                    <h4><span>Launch</span></h4>
                </Modal> :
                <MobileUi 
                    handleRoofViewCallBack={handleRoofViewCallBack}
                    closeActiveUi={closeActiveUi}
                    isToggleOpen={isToggleOpen}
                    targetCurrentElementRef={targetCurrentElementRef}
                    handleUpdateToggleState={handleUpdateToggleState}
                    descriptionModalToggle={descriptionModalToggle}
                    handleDescriptionToggle={handleDescriptionToggle}
                    isRoofViewOpen={isRoofViewOpen}
                    isFullScreen={isFullScreen}
                    selectedComponentRoom={selectedComponentRoom}
                    showMobileHelpSection={showMobileHelpSection}
                    changeCurrentMode={changeCurrentMode}
                    setShowMobileHelpSection={setShowMobileHelpSection}
                    setStartMobileHelpTutorial={setStartMobileHelpTutorial}
                    handleDisableMouseInteraction={handleDisableMouseInteraction}
                />
            }

        </div>
        {startMobileHelpTutorial && <HelpSectionTooltip handleDisableMouseInteraction={handleDisableMouseInteraction} setStartHelpTutorial={setStartMobileHelpTutorial} steps={mobileSteps}/>}
    </>
    );
}


export default MobileLandscape;

const MobileUi = ({
    handleRoofViewCallBack,
    closeActiveUi,
    isToggleOpen,
    targetCurrentElementRef,
    handleUpdateToggleState,
    descriptionModalToggle,
    handleDescriptionToggle,
    isRoofViewOpen,
    isFullScreen,
    selectedComponentRoom,
    showMobileHelpSection,
    changeCurrentMode,
    setShowMobileHelpSection,
    setStartMobileHelpTutorial,
    handleDisableMouseInteraction
}:any) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setIsLoading(false);
        }, 4000);
    }, []);

    return (
        <>{isLoading ? <Loader /> :
                    <div className="outer_row no-select">
                        <div className="item_leftSide">
                            <div className="leftSide_outer Mobile_Hide_Show_Toggle">
                                
                                <div className="leftSide_img">
                                    {/* <img src={question} alt="img" /> */}
                                    {/* <img src={<MobileLogo />} alt="Mobile-logo" /> */}
                                    {!selectedComponentRoom && <MobileLogo />}
                                </div>
                                {isMobile && selectedComponentRoom && !descriptionModalToggle && <MobileDescriptionModal />}
                                {/* Building Type && RoomType */}
                                <ComponentDescriptionModalToggle
                                    handleDescriptionToggle={handleDescriptionToggle}
                                    descriptionModalToggle={descriptionModalToggle}
                                    selectedComponentRoom={selectedComponentRoom}
                                />
                                <div ref={targetCurrentElementRef} className="leftSide_dropdown">
                                    <LeftSideMobileContent 
                                        isToggleOpen={isToggleOpen} 
                                        handleUpdateToggleState={handleUpdateToggleState} 
                                        showMobileHelpSection={showMobileHelpSection}
                                        setStartMobileHelpTutorial={setStartMobileHelpTutorial}
                                        setShowMobileHelpSection={setShowMobileHelpSection}
                                        isLoading={isLoading}
                                        handleDisableMouseInteraction={handleDisableMouseInteraction}
                                    />
                                    <MobileComponent isToggleOpen={isToggleOpen} handleUpdateToggleState={handleUpdateToggleState}/>
                                    

                                </div>
                                {/* RoofView */}
                                {!selectedComponentRoom && <MobileRoofView handleRoofViewCallBack={handleRoofViewCallBack}/>}
                            </div>
                        </div>
                        {/* FooterSection */}
                        <div className="middleSide_item mobileSide_landscape">
                            {changeCurrentMode == "Manual" ? <>{!selectedComponentRoom && <MobileSlider />}</> : <MobileAutoMode />}
                        </div>
                        <div className="rightSide_landscape">
                            {selectedComponentRoom || isRoofViewOpen ? <div className="back_next_buttons">
                                <MobileBackButton closeActiveUi={closeActiveUi}/>
                                <MobileCloseButton closeActiveUi={closeActiveUi}/>
                            </div> : ""}
                            {/* Right Section */}
                            {!selectedComponentRoom && <MobileCompass isFullScreen={isFullScreen} />}
                            {!selectedComponentRoom && <MobileNavbar />}
                            {/* {!selectedComponentRoom && <MobileThemeMode />} */}
                            {isRoofViewOpen || !selectedComponentRoom && (
                                <div className="light-dark-mode-right step step-8">
                                    <ThemeMode first={0.215} second={0.39} third={0.57} fourth={0.760} />
                                </div>
                            )}
                            {!selectedComponentRoom && <MobileMode />}
                        </div>
        </div>}</>
    );
}

const MobileBackButton = ({closeActiveUi}:any) => {
    return (
        <button onTouchStart={closeActiveUi} className="back_btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
            <path d="M16.6562 5.6875L9.34375 13L16.6562 20.3125" stroke="white" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>Back</button>
    );
}

const MobileCloseButton = ({closeActiveUi}:any) => {
    return (
        <button onTouchStart={closeActiveUi} className="close_btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
            <path d="M8.75 8.75L21.25 21.25M8.75 21.25L21.25 8.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>Close</button>
    );
}