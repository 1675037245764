import { Dispatch } from "redux";
import { ActionType } from '../action-types';
import { Action } from "../actions/media";

export const PlayPauseAction = (playPauseStatus: boolean) => {
    return async (dispatch: Dispatch<Action>) => {
        try {
            dispatch({
                type: ActionType.PLAY_PAUSE_STATUS,
                payload: playPauseStatus
            })
        } catch (e) {
            console.log(e);
        }
    }
}

// export const nextAction = (nextItem: string) => {
//     return {
//         type: ActionType.NEXT_ITEM,
//         payload: nextItem
//     }
// }

// export const previousAction = (previousItem: string) => {
//     return {
//         type: ActionType.PREVIOUS_ITEM,
//         payload: previousItem
//     }
// }

export const setCameraIndex = (cameraIndex: number | null) => {
    return {
        type: ActionType.DEFAULT_CAMERA_INDEX,
        payload: cameraIndex
    } 
}
