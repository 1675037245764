
interface PriceType {
    price: string,
    className: {
        divRow: string,
        // divTag: string,
        spanTag: string,
        div_col_06: string,
    }
}

export const Price = (props: PriceType) => {

    return (
        <div className={props.className.divRow}>
            <div className={props.className.div_col_06}>
                <div>
                    <div className="price-new-div">
                        <h4>Price</h4>
                        <p>
                            {/* <del>{`MRP Rs${mrp}`}</del>  */}
                            <span className={props.className.spanTag}>{`Rs ${props.price}`}</span>
                        </p>
                    </div>
                    <p className="inclusive-text">(Inclusive of all taxes)</p>
                </div>
            </div>
            <div className={props.className.div_col_06}>
                <button className="buy-now-btn">buy now 
                <span>
                 <img src="/images/buy-now.png" alt="logo" />
               </span>
                </button>
            </div>
        </div>
    );
}