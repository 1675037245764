import UseToggle from "../UseToggle";
import arrow_left from "../../assets/image/arrow-left.png";
import TypeOfBuildingComponent from "./TypeOfBuildingComponent";
import BlinkEffect from "../Common/BlinkEffect";
import ToolTextPermissionBox from "../Common/ToolTextPermissionBox";

export const Navbar = ({
  helpButtonToggle, 
  showHelpSection, 
  setStartHelpTutorial, 
  setShowHelpSection, 
  isLoading, 
  handleDisableMouseInteraction,
  active, 
  dispatch,
  items,
  allBuildingData,
  handleSelectBuilding
}:any) => {
  
  const { 
    isToggleOpen, 
    targetCurrentElementRef, 
    handleUpdateToggleState
  } = UseToggle([false,false])

  return (
    <nav className="dropdown_expand type-building-button">
      <div onContextMenu={(e) => e.preventDefault()} ref={targetCurrentElementRef} className="dropdown_collapse" id="navbarSupportedContent">
        <ul className="dropdown_navbar_nav" style={{userSelect:"none"}} id="navbarActive">
          <BlinkEffect showHelpSection={showHelpSection}>
            <TypeOfBuildingComponent
              index={0}
              active={active}
              dispatch={dispatch}
              arrow_left={arrow_left}
              isToggleOpen={isToggleOpen}
              navbarOpen={items.navbarState}
              allBuildingData={allBuildingData}
              handleSelectBuilding={handleSelectBuilding}
              helpButtonToggle={helpButtonToggle}
              handleUpdateToggleState={handleUpdateToggleState}
            />
          </BlinkEffect>

          {showHelpSection && !isLoading && <ToolTextPermissionBox handleDisableMouseInteraction={handleDisableMouseInteraction} setStartHelpTutorial={setStartHelpTutorial} setShowHelpSection={setShowHelpSection}/>}
          
          {/* {currentActiveRoomtype !== "Empty" && <TypeOfRoomComponent
            index={1}
            dispatch={dispatch}
            arrow_left={arrow_left}
            isToggleOpen={isToggleOpen}
            navbarOpen={items.navbarState}
            typeOfRoom={items.currentItems}
            helpButtonToggle={helpButtonToggle}
            handleSelectRoom={handleSelectRoom}
            activeRoomType={items.currentActiveRoomType}
            currentActiveRoomtype={currentActiveRoomtype}
            handleUpdateToggleState={handleUpdateToggleState}
           />} */}
        </ul>
      </div>
    </nav>
  );
};
