import "./assets/css/style.css";
import "./assets/css/Toggle.css";
import "./assets/css/loader.css";
import './assets/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import "./component/footersection/arrow.css";
import BuildingData from "./BuildingData.json";
import BuildingData1 from "./BuildingData1.json";
import { useActions } from "../src/hooks/use-actions";
import { Root } from "./BuildingDataType/BuildingData";
import { connectToWebRTC } from "./services/navbarServices";
// import { DefaultBuildingData } from './services/buildingData';
import VirtualExperienceComponentLoad from "./VirtualExperienceComponent";

const App = () => {

    const [isShow, setIsShow] = useState(true);
    const { 
        // DefaultBuildingAction, 
        ThemeModeAction } = useActions();  

    // useEffect(() => {
    //     const buildingData = DefaultBuildingData();
    //     if (buildingData) {
    //         DefaultBuildingAction(buildingData);
    //     }
    // }, []);

    useEffect(() => {
        let currentBuildingDataLoaded = true
        ThemeModeAction(currentBuildingDataLoaded ? BuildingData1 : BuildingData as Root)
    }, [BuildingData, BuildingData1]);


    var currentScrollPos = window.pageYOffset;
    useEffect(() => {
        window.addEventListener("scroll", function () {
            var newScrollPos = window.pageYOffset;
            if (newScrollPos > currentScrollPos) {
                document.body.style.overflow = "hidden";
            }
            currentScrollPos = newScrollPos;
        });
    }, [currentScrollPos]);

    const handleStartEmulator = () => {
        setIsShow(false);
        connectToWebRTC();
    }

    return (
        <VirtualExperienceComponentLoad
            isShow={isShow}
            handleStartEmulator={handleStartEmulator}
        />
    );
}
export default App;

