import { Webrtc } from "./webrtc";

export const componentSelection = (component: string) => {
    const selectedComponent =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": "Selected",
        }
    }
    // console.log("****componentSelection****", selectedComponent)
    Webrtc.sendJSON(selectedComponent);
};


export const componentUnSelection = (component: string) => {
    const selectedComponent =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": "Unselected",
        }
    }
    // console.log("****componentSelection****", selectedComponent)
    Webrtc.sendJSON(selectedComponent);
};

export const componentViewMode = (component: string) => {
    const selectedComponent =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": "View",
        }
    }
    // console.log("****componentSelection****", selectedComponent)
    Webrtc.sendJSON(selectedComponent);
};


export const componentSwitchMode = (component: string, setSwitchMode:any) => {
    const switchMode =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": "Switch",
            "Switch_Input":setSwitchMode,
        }
    }
    // console.log("****componentSelection****", switchMode)
    Webrtc.sendJSON(switchMode);
};

export const componentChangeableMode = (component: string, currentMode:any) => {
    const changeableMode =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": "Change",
            "Option": currentMode,
        } 
    }
    // console.log("****componentSelection****", changeableMode)
    Webrtc.sendJSON(changeableMode);
};

export const componentHoveredMode = (component:string, type:string,currentItem:string) => {
    const hoverMode =
    {
        "C_Type": "WtoE",
        "WtoE_Input": "Component",
        "Component_Input":
        {
            "Component": component,
            "Type": type,
            "Option": currentItem,
        } 
    }
    // console.log("****HoveredMode****", hoverMode)
    Webrtc.sendJSON(hoverMode);
};

export const componentHover = (componentName:string, mode:string) => {
   const componentHoverMode = {
    "C_Type" : "WtoE",
    "WtoE_Input" :  "Component",
    "Component_Input" :
    {
        "Component" : mode === "Hovered" ? componentName : "",
        "Type" : mode
    }

   }
//    console.log("checking ComponentHoverMode", componentHoverMode)
   Webrtc.sendJSON(componentHoverMode);
}