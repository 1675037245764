import { Price } from "./Price";
import { Color } from "./Color";
import { CloseModal } from "./CloseModal";
import { ModalItems } from "./ModalItems";
import { Description } from "./Description";
import UseStyleDynamically from "./UseStyle";
import { ModalContainer } from "./ModalContainer";
import "./DescriptionModalCss/DescriptionModalWeb.css";
import "./DescriptionModalCss/TestingDescriptionModal.css";

import { DescriptionHeader } from "./DescriptionHeader";

const ComponentDescriptionModalNew = () => {

    const { 
        componentList,
        onCloseModal,
        toShowDoubleListOfDescriptionModal
    }  = UseStyleDynamically();

    return (
        <>
            <ModalItems 
                className={{
                    divRow: 'row',
                    divCol: 'col-md-12',
                    divSetVariations: 'set_variations'
                }}
            />
            <div className="building_sections-new">
                <ModalContainer
                    className={{
                        divRow:'row',
                        divMainSet:'main_set',
                        divContainer:'container',
                        divSetInnerBg:'set_inner_bg_new'
                    }}
                    componentList={componentList}
                    toShowDoubleListOfDescriptionModal={toShowDoubleListOfDescriptionModal}
                    // CloseModal = { 
                    //     <CloseModal 
                    //         className="close_icon_right"
                    //         onCloseModal={onCloseModal}
                    //     />
                    // }
                    DescriptionHeader = { 
                        <DescriptionHeader 
                            header={componentList.Header} 
                            className= { 
                                { 
                                    divRow:' main-new-section', 
                                    div_col_09:' my-auto',
                                    div_col_03:' logo-right',
                                    fortisImg:''
                                }
                            }
                        />
                    }

                    Description = { 
                        <Description 
                            description={componentList.Description} 
                            className={{
                                descriptionStyle:"col-md-12 description_border",
                                divRow:'row'
                            }} 
                        />
                    }
                  
                  
                    Color = { 
                        <Color 
                            color= {componentList.Color} 
                            className= { 
                                { 
                                    row:'row',
                                    color_section:"color-section",
                                    col_md_02:'col-md-2',
                                    col_md_10:"col-md-10",
                                    fabric_color:"fabric-color",
                                    // divTag:'col-md-12 set_color', 
                                    // pTag:'wine_clr'
                                }
                            }
                        />
                    }
                    Price = { 
                        <Price 
                            price= {componentList.Price} 
                            className= { 
                                { 
                                    divRow: 'row',
                                    div_col_06:"col-md-6 spacing-price-section my-auto",
                                    // divTag:'description_price', 
                                    spanTag:'current_price'
                                }
                            }
                        />
                    }
                />
            </div>
        </>
    );
}


export default ComponentDescriptionModalNew;