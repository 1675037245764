import classnames from "classnames";
import { useState, Fragment, useEffect, useRef } from "react";
import { Navbar } from "./leftsection/Navbar";
import RoofView from "./rightsection/RoofView";
import { Compass } from "./rightsection/Compass";
import { useActions } from "../hooks/use-actions";
import { MediaController } from "./MediaController";
import { ThemeMode } from "./rightsection/ThemeMode";
import { AutoToggle } from "./leftsection/AutoToggle";
import FullScreenSvgIcons from "./FullScreenSvgIcons";
import HomeComponentHandler from "./HomeComponentHandler";
import SnapshotTaker from "./ScreenshotCapture/SnapshotTaker";
import { useTypedSelector } from "../hooks/use-typed-selector";
import DisplayToggleVisibility from "./DisplayToggleVisibility";
import { FooterNavigation } from "./footersection/FooterNavigation";
import ComponentDescriptionModalNew from "./CommonDescriptionModal/TestingDescriptionModal";
import { SubComponentMenu } from "./leftsection/SubComponentMenu";
import TestingComponent from "./leftsection/TestingComponent";
import NewHomeLogo from "./SvgIcons/NewHomeLogo";
import ComponentDescriptionModalToggle from "./Common/ComponentDescriptionModalToggle";
import { DefaultBuildingData } from "../services/buildingData";
import { Loader } from "./MainScreen";
import screenfull from "screenfull";
import { componentUnSelection } from "../services/componentServices";
import { roofViewMode } from "../services/navbarServices";
import HelpSection from "./Common/HelpSection";
import HelpLeftIcon from "./SvgIcons/HelpLeftIcon";
import HelpSectionTooltip from "./Common/HelpSectionTooltip";
import TypeOfRoom from "./leftsection/TypeOfRoom";
import { webSteps } from "./Common/ToolTipSteps";
import FullScreenMode from "./rightsection/FullScreenMode";
import NavbarHandler from "./leftsection/NavbarHandler";
import { Webrtc } from "../services/webrtc";
import HistoryControl from "./leftsection/HistoryControl";

export const Home = ({ handleDisableMouseInteraction, uiElementRef }: any) => {
  const {
    isShow,
    isFullScreen,
    handleShowContent,
    handleClickFullScreen,
    setIsFullScreen,
  } = HomeComponentHandler();

  const [isLoading, setIsLoading] = useState(true);

  const { DefaultBuildingAction, isElementHover, selectedComponentItem } =
    useActions();

  useEffect(() => {
    const buildingData = DefaultBuildingData();
    if (buildingData) {
      DefaultBuildingAction(buildingData);
    }
  }, []);

  const { items, active, allBuildingData, dispatch, handleSelectBuilding } =
    NavbarHandler();

  const [isHover, setIsHover] = useState(false);
  const [isSvgHover, setIsSvgHover] = useState(false);
  const [helpButtonToggle, setHelpButtonToggle] = useState(false);
  const [themeModeHover, setThemeModeHover] = useState(false);
  const [descriptionModalToggle, setDescriptionModalToggle] = useState(false);
  const { changeCurrentMode } = useTypedSelector(({ mode }) => mode);
  const { selectedComponentRoom }: any = useTypedSelector(
    ({ component }) => component
  );
  const { defaultRoofViewState }: any = useTypedSelector(
    ({ allscreens }) => allscreens
  );

  const handleMouseEnter = () => {
    isElementHover(true);
  };

  const handleMouseLeave = () => {
    isElementHover(false);
  };

  const handleDescriptionToggle = () => {
    setDescriptionModalToggle((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      console.log(event.code);
      Webrtc.sendKeyboardInput(event.key, true);
    });

    // Event listener for keyup
    document.addEventListener("keyup", (event: KeyboardEvent) => {
      Webrtc.sendKeyboardInput(event.key, false);
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const [isRoofViewOpen, setIsRoofViewOpen] = useState(false);

  const closeActiveUi = () => {
    componentUnSelection(selectedComponentRoom);
    selectedComponentItem("");
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
    setIsRoofViewOpen(false);
    roofViewMode(true);
    setIsFullScreen(false);
  };

  const handleRoofViewCallBack = (value: any) => {
    setIsRoofViewOpen(value);
  };

  const handleHelpButtonToggle = () => {
    setHelpButtonToggle((prev) => !prev);
  };

  //  SHOWING HELP SECTION ONLY ONE TIME ON APP LOAD
  // const [helpSectionShown, setHelpSectionShown] = useState(true);
  const [startHelpTutorial, setStartHelpTutorial] = useState(false);
  const [showHelpSection, setShowHelpSection] = useState(true);

  //  useEffect(() => {
  //   const isWelcomeShown = localStorage.getItem('helpSectionShown');

  //   if (isWelcomeShown) {
  //     setHelpSectionShown(false);
  //   } else {
  //     setHelpSectionShown(true);
  //     localStorage.setItem('helpSectionShown', 'true');
  //   }
  // }, []);

  return (
    <>
      {/* {!selectedComponentRoom && } */}
      {!selectedComponentRoom && !isShow ? (
        <TypeOfRoom helpButtonToggle={helpButtonToggle} items={items} />
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!selectedComponentRoom && (
            <FullScreenMode
              setIsHover={setIsHover}
              setIsSvgHover={setIsSvgHover}
              isFullScreen={isFullScreen}
              handleClickFullScreen={handleClickFullScreen}
              isSvgHover={isSvgHover}
              isHover={isHover}
              handleShowContent={handleShowContent}
              helpButtonToggle={helpButtonToggle}
              isShow={isShow}
            />
          )}
          {!isShow && (
            <div
              ref={uiElementRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onContextMenu={(e) => e.preventDefault()}
              className="container hideshowdescription"
            >
              <ComponentDescriptionModalToggle
                handleDescriptionToggle={handleDescriptionToggle}
                descriptionModalToggle={descriptionModalToggle}
                selectedComponentRoom={selectedComponentRoom}
              />
              {selectedComponentRoom ||
              (isRoofViewOpen && defaultRoofViewState?.State !== "Off") ? (
                <div className="back_close_btn">
                  <BackButton closeActiveUi={closeActiveUi} />
                  <CloseButton closeActiveUi={closeActiveUi} />
                </div>
              ) : (
                <HistoryControl
                  // onUndo={() => {}}
                  // onRedo={() => {}}
                  canUndo={true}
                  canRedo={true}
                />
              )}
              {!selectedComponentRoom && (
                <RoofView
                  handleRoofViewCallBack={handleRoofViewCallBack}
                  helpButtonToggle={helpButtonToggle}
                />
              )}
              <div className="row outer-sec">
                <div className="col-md-2">
                  <div
                    className={`estate-left-sec ${
                      selectedComponentRoom && "component_left_section"
                    }`}
                  >
                    <div className="site-logo step step-1">
                      {!selectedComponentRoom && <NewHomeLogo />}
                    </div>
                    {!selectedComponentRoom && (
                      <HelpButtonToggle
                        helpButtonToggle={helpButtonToggle}
                        handleHelpButtonToggle={handleHelpButtonToggle}
                      />
                    )}{" "}
                    {/* remove this condition - helpSectionShown */}
                    {!selectedComponentRoom && (
                      <Navbar
                        helpButtonToggle={helpButtonToggle}
                        showHelpSection={showHelpSection}
                        setStartHelpTutorial={setStartHelpTutorial}
                        setShowHelpSection={setShowHelpSection}
                        isLoading={isLoading}
                        handleDisableMouseInteraction={
                          handleDisableMouseInteraction
                        }
                        items={items}
                        active={active}
                        allBuildingData={allBuildingData}
                        dispatch={dispatch}
                        handleSelectBuilding={handleSelectBuilding}
                      />
                    )}
                    <div className="Main-Center-Section">
                      <TestingComponent helpButtonToggle={helpButtonToggle} />
                      <SubComponentMenu helpButtonToggle={helpButtonToggle} />
                    </div>
                  </div>
                  {!selectedComponentRoom && (
                    <SnapshotTaker
                      helpButtonToggle={helpButtonToggle}
                      isFullScreen={isFullScreen}
                    />
                  )}
                  {!selectedComponentRoom && (
                    <AutoToggle
                      isFullScreen={isFullScreen}
                      helpButtonToggle={helpButtonToggle}
                    />
                  )}
                </div>
                {!selectedComponentRoom && (
                  <Fragment>
                    {changeCurrentMode == "Manual" ? (
                      <>
                        {!selectedComponentRoom && (
                          <FooterNavigation
                            isFullScreen={isFullScreen}
                            helpButtonToggle={helpButtonToggle}
                          />
                        )}
                      </>
                    ) : (
                      <div className="col-md-8">
                        <div className="middle-class">
                          <MediaController />
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
                <div className="col-md-2">
                  {selectedComponentRoom && !descriptionModalToggle && (
                    <ComponentDescriptionModalNew />
                  )}

                  {isRoofViewOpen ||
                    (!selectedComponentRoom && (
                      <div
                        onMouseEnter={() => setThemeModeHover(true)}
                        onMouseLeave={() => setThemeModeHover(false)}
                        className="light-dark-mode-right step step-13"
                      >
                        <ThemeMode
                          first={0.215}
                          second={0.38}
                          third={0.56}
                          fourth={0.76}
                        />
                        {themeModeHover && helpButtonToggle && (
                          <HelpSection
                            step="2"
                            text="Change time of day"
                            iconSpace="right_helpTooltip"
                            className="lightdark_toggle"
                            icon={<HelpLeftIcon />}
                          />
                        )}
                      </div>
                    ))}
                  {!selectedComponentRoom && (
                    <Compass
                      isFullScreen={isFullScreen}
                      helpButtonToggle={helpButtonToggle}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {startHelpTutorial && (
        <HelpSectionTooltip
          handleDisableMouseInteraction={handleDisableMouseInteraction}
          setStartHelpTutorial={setStartHelpTutorial}
          steps={webSteps}
        />
      )}
    </>
  );
};

const BackButton = ({ closeActiveUi }: any) => {
  return (
    <button onClick={closeActiveUi}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 12 20"
        fill="none"
      >
        <path
          d="M10.2188 1.5625L1.78125 10L10.2188 18.4375"
          stroke="white"
          strokeWidth="2.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>{" "}
      Back
    </button>
  );
};

const CloseButton = ({ closeActiveUi }: any) => {
  return (
    <button onClick={closeActiveUi}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M1.75 1.75L14.25 14.25M1.75 14.25L14.25 1.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Close
    </button>
  );
};

const HelpButtonToggle = ({
  handleHelpButtonToggle,
  helpButtonToggle,
}: any) => {
  return (
    <div className="help_toggle">
      <div className="help_invisible">
        <label className="help_switch">
          <input onClick={handleHelpButtonToggle} type="checkbox" />
          <span className="help_slider help_round"></span>
        </label>
        <p className={`${helpButtonToggle && "active_help_text"}`}>Help? </p>
      </div>
    </div>
  );
};
