

import { ActionType } from "../action-types";
import { Action } from "../actions/footernavbar";
import { BuildingsData } from "../../services/buildingData";

interface hoverStateType {
    is_hover:boolean | null
}

export const INITIAL_STATE:hoverStateType = {
    is_hover: null
}

export default (state: hoverStateType = INITIAL_STATE, action: any): hoverStateType => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.MAIN_SCREEN_SUCCESS:
            return { ...state, is_hover: action.payload }
        default:
            return state;
    }
};