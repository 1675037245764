
const MobileLogo = () => {
    return (
        <>
            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.9023 23.6545H28.4688V22.6133C28.2271 22.776 27.33 23.371 26.9023 23.6545Z" fill="white" />
                <path d="M3.86328 22.7383V23.7795H5.42975C5.00211 23.5006 4.10497 22.901 3.86328 22.7383Z" fill="white" />
                <path d="M28.6683 3.95337V3.24219C28.4916 3.34444 18.0516 9.18735 17.8749 9.28961H17.8703V10.0426L27.8966 16.1458C27.5712 16.3643 27.139 16.6525 26.6323 16.9918L26.5672 16.9546C26.1117 16.6757 17.861 11.6602 17.861 11.6602V12.4133C18.4002 12.7386 25.712 17.187 26.0373 17.3869C25.6422 17.6519 25.2192 17.9354 24.7776 18.2282L17.861 14.0216V14.7792L24.0339 18.535L24.1827 18.6233C23.7643 18.9022 23.3413 19.1858 22.923 19.4693L17.861 16.3875V17.1452L22.328 19.8644C20.0689 21.3844 17.9958 22.7696 17.7773 22.9183L17.8006 23.5505C18.6466 22.9881 27.9663 16.7594 28.5149 16.3968L28.4916 15.7507L25.9815 14.2214C26.1489 14.1284 28.6497 12.72 28.6683 12.7107V12.0042C28.3615 12.1762 25.6748 13.6822 25.3727 13.8496L24.113 13.0826C24.3965 12.9246 28.4591 10.6469 28.6683 10.5307V9.81952C28.2871 10.0333 23.8805 12.5016 23.504 12.7107L22.235 11.9391C22.5185 11.7811 28.4126 8.47615 28.6683 8.33671V7.62552C28.3196 7.82074 23.1321 10.7259 21.6261 11.5673L20.3572 10.7957C20.6778 10.6144 28.3661 6.31006 28.6636 6.14272V5.43153C26.8415 6.45416 20.0596 10.2518 19.7482 10.4284L18.4792 9.65682L28.4591 4.06493L28.6683 3.95337Z" fill="white" />
                <path d="M21.1038 23.6533C22.4193 22.7794 26.705 19.9392 28.4714 18.7633V18.0195C25.9474 19.7022 22.4565 22.0077 19.9883 23.6533H21.1038Z" fill="white" />
                <path d="M24.5617 23.6519C24.8034 23.4939 28.1966 21.2395 28.4663 21.0582V20.3145C26.8579 21.3882 25.0451 22.5828 23.4414 23.6519H24.5617Z" fill="white" />
                <path d="M11.627 15.877L11.2227 16.1233V16.881L11.627 16.6346V15.877Z" fill="white" />
                <path d="M12.9062 15.8582L14.4634 14.91V14.1523L12.9062 15.1006V15.8582Z" fill="white" />
                <path d="M3.86328 18.8883C5.62963 20.0643 9.91535 22.9044 11.2308 23.7782H12.351C9.8828 22.1328 6.39194 19.8226 3.86791 18.1445V18.8883H3.86328Z" fill="white" />
                <path d="M3.86328 21.1852C4.13753 21.3665 7.52613 23.6162 7.76785 23.7789H8.88809C7.27976 22.7098 5.46693 21.5105 3.86328 20.4414V21.1852Z" fill="white" />
                <path d="M14.462 17.2748V16.5172C14.0623 16.7635 12.626 17.6328 12.2541 17.8605C12.2541 17.2191 12.2541 16.1267 12.2541 15.4899V15.3783V13.877L14.462 12.5336V11.7759L7.61511 15.9408C7.16423 16.215 5.69537 17.1122 5.69537 17.1122C5.19335 16.7775 4.76571 16.4893 4.44034 16.2708L14.462 10.1723V9.4146H14.4574C14.2482 9.29841 3.86858 3.48341 3.66406 3.36719V4.07839L3.87326 4.19459L13.8531 9.78646L12.5841 10.5581C12.2959 10.3954 5.4676 6.57451 3.66871 5.56116V6.27238C3.96621 6.4397 11.6545 10.744 11.9752 10.9253L10.7062 11.6969C9.19553 10.8509 4.02663 7.95505 3.66406 7.75516V8.46635C3.91508 8.6058 9.81374 11.9107 10.0973 12.0688L8.82832 12.8404C8.4518 12.6312 4.04522 10.163 3.66406 9.94916V10.6603C3.87326 10.7766 7.93583 13.0542 8.21936 13.2122L6.95968 13.9792C6.65756 13.8119 3.97084 12.3058 3.66406 12.1339V12.8404C3.68267 12.8497 6.18344 14.2581 6.35079 14.3511L3.84072 15.8804L3.81746 16.5265C4.36596 16.8937 13.6857 23.1177 14.5318 23.6848L14.555 23.0527C14.3365 22.9039 12.2634 21.5187 9.99504 19.9987C9.99501 19.9941 13.2023 18.0418 14.462 17.2748ZM10.4738 14.9647L11.8172 14.1466V15.6479C11.8172 16.2615 11.8172 17.5026 11.8125 18.1301C11.6266 18.2463 10.9386 18.66 10.7666 18.7669L10.4738 18.9482V14.9647ZM8.14036 18.753L9.38144 18V17.2423L7.54538 18.3579C7.1038 18.0651 6.6808 17.7815 6.28567 17.5166L10.0322 15.2342C10.0322 16.4754 10.0276 17.9814 10.0276 19.2132L9.40003 19.5943C8.98171 19.3154 8.55872 19.0319 8.14036 18.753Z" fill="white" />
                <path d="M7.29878 28.4914L6.67593 27.5989C6.63872 27.6036 6.59688 27.6036 6.5597 27.6036H5.87177V28.4914H5.30469V25.4375H6.5597C7.36386 25.4375 7.86122 25.8465 7.86122 26.5252C7.86122 26.9853 7.62416 27.3293 7.21047 27.4874L7.91236 28.4914L7.29878 28.4914ZM6.5318 25.9209H5.86712V27.1388H6.5318C7.02919 27.1388 7.28485 26.911 7.28485 26.5298C7.28485 26.1487 7.02919 25.9209 6.5318 25.9209Z" fill="white" />
                <path d="M11.2987 28.0173V28.4914H9.01172V25.4375H11.2383V25.9116H9.57882V26.7065H11.0523V27.1713H9.57882V28.0126H11.2987V28.0173Z" fill="white" />
                <path d="M14.4398 27.7849H12.9151L12.613 28.4914H12.0273L13.3986 25.4375H13.9564L15.3323 28.4914H14.742L14.4398 27.7849ZM14.2492 27.3386L13.6728 26.0046L13.1011 27.3386H14.2492Z" fill="white" />
                <path d="M16.2461 25.4375H16.8132V28.008H18.4076V28.4868H16.2461V25.4375Z" fill="white" />
                <path d="M19.3835 25.4375H19.946V28.4914H19.3789V25.4375H19.3835Z" fill="white" />
                <path d="M21.9157 25.9221H20.9023V25.4434H23.4914V25.9221H22.4781V28.4926H21.911V25.9221H21.9157Z" fill="white" />
                <path d="M25.7149 27.413V28.4914H25.1525V27.4223L23.9531 25.4375H24.5574L25.4499 26.925L26.3517 25.4375H26.9095L25.7149 27.413Z" fill="white" />
                <path d="M5.21094 32.0118L5.31785 31.8027C5.50379 31.9839 5.83379 32.1188 6.17314 32.1188C6.66121 32.1188 6.87038 31.9142 6.87038 31.6586C6.87038 30.9474 5.27135 31.3843 5.27135 30.4221C5.27135 30.041 5.56886 29.7109 6.22893 29.7109C6.52175 29.7109 6.82388 29.7946 7.03306 29.9387L6.94009 30.1572C6.71699 30.0131 6.45667 29.9433 6.22893 29.9433C5.7548 29.9433 5.54096 30.1526 5.54096 30.4128C5.54096 31.124 7.13997 30.6964 7.13997 31.64C7.13997 32.0211 6.83782 32.3465 6.17314 32.3465C5.78732 32.3512 5.40616 32.2164 5.21094 32.0118Z" fill="white" />
                <path d="M9.89844 31.0311C9.89844 30.2688 10.4748 29.7109 11.2604 29.7109C11.6369 29.7109 11.9809 29.8365 12.2086 30.0875L12.0366 30.2594C11.8275 30.0456 11.5671 29.9527 11.2697 29.9527C10.6422 29.9527 10.1727 30.4128 10.1727 31.0264C10.1727 31.64 10.6422 32.1002 11.2697 32.1002C11.5671 32.1002 11.8275 32.0072 12.0366 31.7887L12.2086 31.9607C11.9809 32.2117 11.6369 32.3419 11.2557 32.3419C10.4748 32.3512 9.89844 31.7934 9.89844 31.0311Z" fill="white" />
                <path d="M16.7405 31.6355H15.2949L14.9835 32.3281H14.6953L15.8806 29.7344H16.1502L17.3355 32.3281H17.0473L16.7405 31.6355ZM16.6383 31.4124L16.0154 30.0179L15.3925 31.4124H16.6383Z" fill="white" />
                <path d="M20.1523 29.7344H20.4266V32.0911H21.8815V32.3281H20.1523V29.7344Z" fill="white" />
                <path d="M26.5548 32.0911V32.3281H24.7188V29.7344H26.4991V29.9714H24.993V30.8964H26.3364V31.1289H24.993V32.0911H26.5548Z" fill="white" />
                <rect x="1" y="1" width="30" height="34.4577" stroke="white" strokeWidth="0.746583" />
            </svg>
        </>
    );
}


export default MobileLogo;
