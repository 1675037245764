import { Home } from "./component/Home";
import { isMobile } from "react-device-detect";
import { Loader, MainScreen } from "./component/MainScreen";
import { useState, useEffect, useCallback, useRef } from "react";
import "./component/mobileLandscape/modal-components/ModalDialog.css";
import HomeComponentHandler from "./component/HomeComponentHandler";

interface ComponentProps {
  isShow: boolean;
  handleStartEmulator: () => void;
}

const VirtualExperienceComponentLoad = ({
  isShow,
  handleStartEmulator,
}: ComponentProps) => {
  const [loading, setLoading] = useState(false);

  const { isFullScreen } = HomeComponentHandler();

  const handleWindowBackgroundImageLoad = useCallback(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    handleWindowBackgroundImageLoad();
    return () => handleWindowBackgroundImageLoad();
  }, [handleWindowBackgroundImageLoad]);

  const [slotData, setSlotData] = useState({ slots: 0 });
  const [stopDataFetching, setStopDataFetching] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch("https://demo.realityscale.com/api/slots");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setSlotData(jsonData);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchData();

    if (stopDataFetching) {
      const interval = setInterval(fetchData, 2000);

      return () => clearInterval(interval);
    }
  }, [stopDataFetching]);

  const handleStopFetching = (value: boolean) => {
    setStopDataFetching(value);
  };

  return (
    <div className={`Estate ${isMobile && "landscapeview_mobile"}`}>
      {loading ? (
        <Loader />
      ) : (
        <MainScreen
          isShow={isShow}
          slotData={slotData}
          loading={loading}
          isMobile={isMobile}
          isFullScreen={isFullScreen}
          handleStartEmulator={handleStartEmulator}
          handleStopFetching={handleStopFetching}
        />
      )}
    </div>
  );
};

export default VirtualExperienceComponentLoad;
