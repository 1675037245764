import { useState, Fragment } from "react";
import BuildingData from "../../BuildingData.json";
import ThemeModeSvgIcons from "./ThemeModeSvgIcons";
import TimeIcons from "../SvgIcons/Time1";
import TimeIcons2 from "../SvgIcons/Time2";
import TimeIcons3 from "../SvgIcons/Time3";
import TimeIcons4 from "../SvgIcons/Time4";
import {
  ChangeDayNightMode,
  ThemeModeChange,
} from "../../services/modeservices";
import InputRangeSlider from "./InputRangeSlider";
import Animation from "./ThemeModeAnimation";

export const ThemeMode = ({first, second, third, fourth}:any) => {
  // const [selectedTheme, setSelectedTheme] = useState(
  //   BuildingData.StartingState
  // );
  const [rangeValue, setRangeValue] = useState(0.65);
  const [currentTab, setCurrentTab] = useState("");

  const handleChange = (event: any) => {
    const value = parseFloat(event.target.value);
    setRangeValue(value);
    ChangeDayNightMode(value);
    let currentSelectedTab = ''
    if(value >= 0.00 && value <= 0.20) {
      currentSelectedTab = "first"
    } else if (value >= 0.22 && value <= 0.44) {
      currentSelectedTab = "second"
    } else if(value >= 0.46 && value <= 0.66) {
      currentSelectedTab = "third"
    } else {
      currentSelectedTab = "fourth"
    }
    setCurrentTab(currentSelectedTab)
  };
  // const handleThemeMode = (item: string) => {
  //   setSelectedTheme(item);
  //   ThemeModeChange(item);
  // };

  const timerData:any = {
    first,
    second,
    third,
    fourth
  }

  const setCurrentModeValue = (value: number, char:string ) => {
    setRangeValue(value);
    ChangeDayNightMode(value);
    setCurrentTab(char)
  };

  const handleTouchMove = (event:any) => {
    const newValue = parseFloat(event.target.value);
    setRangeValue(newValue);
  };

  return (
    <>
      {/* <div onContextMenu={(e) => e.preventDefault()}>
        <div className={`mode_ouuter ${!themeOpen && "mode_clock"}`}>
          <div
            onClick={() => {
              setThemeOpen(!themeOpen);
              setIsHover(false);
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className="close_mode "
          >
            {themeOpen ? (
              <img src="/images/svgIcons/close_ic.svg" alt="closeButton" />
            ) : (
              <ThemeModeSvgIcons
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill={themeOpen || isHover ? "#3A3A3A" : "white"}
              />
            )}
          </div>
          {themeOpen && (
            <div className="outer_mode">
              {BuildingData.StateList.map((item: string, index: number) => {
                return (
                  <Fragment key={index}>
                    <div className={`sun_mode ${selectedTheme == item && ""}`}>
                      <img
                        onClick={() => handleThemeMode(item)}
                        src={`/images/svgIcons/${
                          selectedTheme == "Day" && selectedTheme == item
                            ? "DayActive"
                            : selectedTheme == "Night" && selectedTheme == item
                            ? "NightActive"
                            : selectedTheme == "Evening" &&
                              selectedTheme == item
                            ? "NightActive"
                            : item
                        }.svg`}
                        alt="img"
                      />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div> */}
      <Animation>
        <InputRangeSlider
          rangeValue={rangeValue}
          currentTab={currentTab}
          TimeIcons={TimeIcons}
          TimeIcons2={TimeIcons2}
          TimeIcons3={TimeIcons3}
          TimeIcons4={TimeIcons4}
          handleChange={handleChange}
          setCurrentModeValue={setCurrentModeValue}
          handleTouchMove={handleTouchMove}
          data={timerData}
        />
      </Animation>
    </>
  );
};
