
import { Dispatch } from "redux";
import { ActionType } from '../action-types';
import { BuildingListType } from "../../type/BuildingData";

export function loadBuildingData(data:BuildingListType) {
    return async (dispatch:Dispatch) => {
         dispatch({
            type: ActionType.LOAD_BUILDING_DATA,
            payload:data
        })
    }
}