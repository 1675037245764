import { useState } from "react";
import DollViewSvgIcons from "../SvgIcons/DollViewSvgIcons";
import { DollHouseView } from "../../services/navbarServices";
import HelpSection from "../Common/HelpSection";
import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";

const DollRoofView = ({ helpButtonToggle, isRoofViewOpen }: any) => {
  const [isHover, setIsHover] = useState(false);
  const [dollIsOpen, setDollIsOpen] = useState(false);

  const handleDollHouseView = () => {
    setDollIsOpen(!dollIsOpen);
    DollHouseView(dollIsOpen);
  };

  return (
    <div
      onClick={handleDollHouseView}
      className={`roofDiv_1 ${
        dollIsOpen && !isRoofViewOpen && "active_roofMode"
      }`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DollViewSvgIcons
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill={dollIsOpen || isHover ? "#3A3A3A" : "white"}
      />
      {isHover && helpButtonToggle && (
        <HelpSection
          text="Model View (unavailable)"
          iconSpace="right_helpTooltip"
          className="DollRoof_toggle"
          icon={<HelpLeftIcon />}
        />
      )}
    </div>
  );
};

export default DollRoofView;
