import { ActionType } from '../action-types';

export const HandleRoomAction = (room: string|null) => {
    return {
        type: ActionType.ROOM,
        payload: room
    }
}

export const setSelectedRoomIndex = (index: number) => {
    return {
        type: 'set_selected_room_index',
        payload: index
    }
}


export const setSelectedRoomTypeIndex = (index: number) => {
    return {
        type: 'set_selected_roomType_index',
        payload: index
    }
}

// export const setSelectedRoomtypeIndex = (index: number) => {
//     return {
//         type: ActionType.SELECTED_ROOMTYPE_INDEX,
//         payload: index
//     }
// }


// export const SetDefaultRoofview = (activeMode: string) => {
//     return {
//         type: ActionType.SET_DEFAULT_ROOF_VIEW,
//         payload: activeMode
//     }
// }


export const DefaultSubcategory = (category: string) => {
    return {
        type: ActionType.DEFAULT_SUBCATEGORY,
        payload: category
    }
}


// export const NumberOfCamerasAvailable = (numberOfCameras:any) => {
//     return {
//         type: ActionType.NUMBER_OF_CAMERAS_AVAILABLE,
//         paylaod: numberOfCameras
//     }
// }

export const updateAvailableComponent = (length: number) => {
    return {
        type: ActionType.AVAILABLE_COMPONENT,
        payload: length
    }
}