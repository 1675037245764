import { RoomType } from "../Types";
import { useState, useEffect } from "react";
import { useActions } from "../../hooks/use-actions";
import { BuildingsData } from "../../services/buildingData";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import ItemListAction from "../ComponentStateService/ItemListAction";
import { StateActionTypes } from "../ComponentStateService/ItemTypes";
import {roomTypeSelect,selectCurrentRoom} from "../../services/navbarServices";



const NavbarHandler = () => {

    const initialState = {
        navbarState:'',
        currentItems:[],
        currentActiveRoomType:'',
        currentBuildingTypeState:'',
    }

    const allBuildingData = BuildingsData();
    const { items, dispatch } = ItemListAction(initialState);
    const { currentActiveRoomtype } = useTypedSelector(({ navbar }) => navbar);
    const { activeRoofView } = useTypedSelector(({ allscreens }) => allscreens);
    const { selectRoom,setDefaultMovingRoom } = useTypedSelector(({ footernavbar }) => footernavbar);
    const { 
        buildingnavData: { 
            BuildingType: { 
                Name, 
                DefaultRoom, 
                RoomTypes 
            }
        } 
    } = useTypedSelector(({ navbar }) => navbar);

    const {
        handleGetStateData,
        setSelectedRoomTypeIndex,
        updateAvailableComponent,
        HandleOnClickBuildingDataAction,
    } = useActions();
    
    const [active, setActive] = useState(Name);
    
    useEffect(() => {
        setActive(Name);
        RoomTypes.forEach((roomType:RoomType) => {
            if (!selectRoom) {
                if (roomType.Room.Name === DefaultRoom) {
                    dispatch({type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: roomType.Room.DefaultRoomType});
                    dispatch({ type: StateActionTypes.LOAD_ROOM_TYPE, currentItems: roomType })
                }
            }
        });
    }, [RoomTypes]);
    
    useEffect(() => {
        RoomTypes.forEach((roomType:RoomType) => {
            if (selectRoom) {
                if (roomType.Room.Name === selectRoom) {
                    dispatch({type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: roomType.Room.DefaultRoomType})
                    dispatch({ type: StateActionTypes.LOAD_ROOM_TYPE, currentItems: roomType })
                }
            }
        });
    }, [selectRoom]);
    
    
    useEffect(() => {
        RoomTypes.forEach((room:RoomType) => {
            if(setDefaultMovingRoom.Room === room.Room.Name) {
                dispatch({ type: StateActionTypes.LOAD_ROOM_TYPE, currentItems: room });
                dispatch({type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: room.Room.DefaultRoomType});  
                // HandleRoomAction(setDefaultMovingRoom.Room) 
            }
        });
    },[setDefaultMovingRoom.Room])
    
    const handleSelectBuilding = (selectBuilding: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const buildingType = selectBuilding.currentTarget.getAttribute("data-name");
        const buildingValue = selectBuilding.currentTarget.getAttribute("data-value");
        HandleOnClickBuildingDataAction(buildingValue);
        if (buildingType) {
            dispatch({type: StateActionTypes.UPDATE_BUILDING_TYPE_STATE, activeBuildingState:buildingType})
        }
    };

    const handleSelectRoom = (selectCurrentRoomTarget: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const roomType = selectCurrentRoomTarget.currentTarget.getAttribute("data-name");
        const roomValue = selectCurrentRoomTarget.currentTarget.getAttribute("data-value");
        const componentLength = selectCurrentRoomTarget.currentTarget.getAttribute("data-component");
        const selectedRoom = selectRoom != "" ? selectRoom : DefaultRoom;
        if (roomType) {
            dispatch({type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: roomType})
            roomTypeSelect(selectedRoom,roomType);
        }
        updateAvailableComponent(componentLength as any)
        setSelectedRoomTypeIndex(parseInt(roomValue as string));
        handleGetStateData(roomType, selectedRoom)
    };

    const retrieveRoomType = (currentRoom:string) => {
        RoomTypes.map((room:RoomType) => {
            if(currentRoom){
                if(room.Room.Name === currentRoom) {
                    if (activeRoofView === 'Active') {
                        selectCurrentRoom(currentRoom)
                    } else {
                        selectCurrentRoom(currentRoom, room.Room.DefaultRoomType);
                    }
                }
            }
        })
    }
    
    return {
        items,
        active,
        allBuildingData,
        currentActiveRoomtype,
        dispatch,
        retrieveRoomType,
        handleSelectRoom,
        handleSelectBuilding
    }
}

export default NavbarHandler;