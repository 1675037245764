
import styled, { keyframes, css } from "styled-components";

const blinkAnimation: any = keyframes`
  0%, 100% {
    background-color: rgba(42, 42, 42, 0.9);
  }
  50% {
    background-color: #F2F2F2;
  }
`;

const WhiteBox: any = styled.div`
  border-radius: 10px;
  position:relative;
  z-index:1000;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.4s ease;
  ${(props: any) =>
        props.blinking &&
        css`
      animation: ${blinkAnimation} 1s infinite;
    `}
`;

const BlinkEffect = ({ children, showHelpSection }: any) => {

    return (
        <>
            <WhiteBox
                blinking={showHelpSection}
            >
                {children}
            </WhiteBox>
        </>
    );
};

export default BlinkEffect;
