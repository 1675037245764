import React, { useEffect, useRef, useState } from "react";
import { Home } from "./Home";
import {
  onmouseDown,
  onMouseMoveType,
  mouseRelease,
  touchInputPressed,
  touchInputReleased,
  touchInputStart,
  scrollingMouseWheel,
} from "../services/mainscreen";
import MobileLandscape from "./mobileLandscape/MobileLandscape";
import Modal from "./mobileLandscape/modal-components/ModalDialog";
import { useTypedSelector } from "../hooks/use-typed-selector";
import styled from "styled-components";
import { Webrtc } from "../services/webrtc";

export const MainScreen = ({
  loading,
  isShow,
  isMobile,
  isFullScreen,
  handleStartEmulator,
  slotData,
  handleStopFetching,
}: any) => {
  const { is_hover } = useTypedSelector(({ main_screen }) => main_screen);
  const [isMatched, setIsMatched] = useState<boolean>(false);
  const [isMouseInteract, setIsMouseInteract] = useState(false);
  const [hideMouse, setHideMouse] = useState(false);
  // For Mouse X and Y POSITIONS:
  const xPos = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    element: any,
    width: any
  ) => {
    // console.log(e.clientX ,e.movementX,'e.clientX ')
    var client_x = e.clientX - element;
    return client_x / width;
  };
  const yPos = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    element: any,
    width: any
  ) => {
    var client_y = e.clientY - element;
    return client_y / width;
  };

  // Capture Mouse Event Outside of Mousedown Element
  // START
  const onPointerDownElement = (event: React.PointerEvent) => {
    // (event.target as HTMLDivElement)?.setPointerCapture(event.pointerId);
    event.stopPropagation();
  };

  const onPointerUpElement = (event: React.PointerEvent) => {
    // (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    event.stopPropagation();
    mouseRelease(0, 0, 0);
  };
  // END
  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    //This part is not needed anymore, and the return broke the mouse event!
    /*let buttonType: string = "";        
        if (event.button === 0) {
            return buttonType = "left";
        }
        else if (event.button === 1) {
            return buttonType = "middle";
        }
        else if (event.button === 2) {
            return buttonType = "right";
        }
        */
    if (event.button === 2) {
      const container = event.currentTarget;

      // setHideMouse(!hideMouse);
      if (container) {
        // const requestPointerLock = container.requestPointerLock;
        // requestPointerLock?.call(container);
        // hide mouse pointer
        document.body.style.cursor = "none";
        // document.body.style.pointerEvents = "none";
        document.body.style.userSelect = "none";
        document.body.style.touchAction = "none";
      }
      //  else {
      // document.body.style.cursor = "auto";
      // document.body.style.userSelect = "auto";
      // document.body.style.touchAction = "auto";
      // }
    }
    const video = document.getElementById("streamingVideoPlayer");
    const rect = video?.getBoundingClientRect();
    const xPosition = xPos(event, rect?.left, rect?.width);
    const yPosition = yPos(event, rect?.top, rect?.height);
    // console.log({ xPosition, yPosition });
    onmouseDown(xPosition, yPosition, event.button);
    event.stopPropagation();
  };
  const handleDisableMouseInteraction = (value: boolean) => {
    setIsMouseInteract(value);
  };

  const handleMouseMove = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const video = document.getElementById("streamingVideoPlayer");

    const isHoveringOverUI = checkIfMouseIsOverUI(ev);

    let xPosition = 0;
    let yPosition = 0;

    if (!isHoveringOverUI) {
      if (document.pointerLockElement != null) {
        xPosition = Math.min(Math.max(ev.movementX, -90), 90);
        yPosition = Math.min(Math.max(ev.movementY, -90), 90);
      } else {
        const rect = video?.getBoundingClientRect();
        xPosition = xPos(ev, rect?.left, rect?.width);
        yPosition = yPos(ev, rect?.top, rect?.height);
      }
    } else {
      xPosition = -1;
      yPosition = -1;
    }
    if (!isMouseInteract) {
      // console.log({ xPosition, yPosition });
      onMouseMoveType(xPosition, yPosition);
    }
    ev.stopPropagation();
  };

  const uiElementRef = useRef<HTMLDivElement>(null);

  const checkIfMouseIsOverUI = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const uiElement = uiElementRef.current;
    if (uiElement && uiElement.contains(ev.target as Node)) {
      return true;
    }
    return false;
  };

  const handleMouseUp = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    /*
        let buttonType: string = "";
        if (event.button === 0) {
            buttonType = "left";
        }
        else if (event.button === 1) {
            buttonType = "middle";
        }
        else if (event.button === 2) {
            buttonType = "right";
        }
        */
    //return if right button of muse is up but if already mouse pointer is hiddne then dont return

    if (event.button === 2) {
      const container = event.currentTarget;
      if (container) {
        document.body.style.cursor = "auto";
        document.body.style.userSelect = "auto";
        document.body.style.touchAction = "auto";
      }
    }

    const video = document.getElementById("streamingVideoPlayer");
    const rect = video?.getBoundingClientRect();
    const xPosition = xPos(event, rect?.left, rect?.width);
    const yPosition = yPos(event, rect?.top, rect?.height);
    event.stopPropagation();
    mouseRelease(xPosition, yPosition, event.button);
  };

  // For Touch X and Y POSITIONS:
  const yTouchPos = (event: any, element: any, height: any) => {
    let client_y;
    [...event.targetTouches].forEach((touch: any) => {
      client_y = (touch.clientY - element) / height;
    });
    return client_y;
  };

  const xTouchPos = (event: any, element: any, width: any) => {
    let client_x;
    [...event.targetTouches].forEach((touch: any) => {
      client_x = (touch.clientX - element) / width;
    });
    return client_x;
  };

  const handleTouchStart = (event: any) => {
    let numberOfCurrentTouches: number = 0;
    const video = document.getElementById("streamingVideoPlayer");
    const rect = video?.getBoundingClientRect();
    const xTouchPosition = xTouchPos(event, rect?.left, rect?.width);
    const yTouchPosition = yTouchPos(event, rect?.top, rect?.height);
    [...event.targetTouches].forEach((touch: any) => {
      numberOfCurrentTouches = touch.identifier;
    });
    touchInputStart(xTouchPosition, yTouchPosition, numberOfCurrentTouches);
  };

  const handleTouchMove = (event: any) => {
    let numberOfCurrentTouches: number = 0;
    const video = document.getElementById("streamingVideoPlayer");
    const rect = video?.getBoundingClientRect();
    const xTouchPosition = xTouchPos(event, rect?.left, rect?.width);
    const yTouchPosition = yTouchPos(event, rect?.top, rect?.height);
    [...event.targetTouches].forEach((touch: any) => {
      numberOfCurrentTouches = touch.identifier;
    });
    if (!isMouseInteract) {
      touchInputPressed(xTouchPosition, yTouchPosition, numberOfCurrentTouches);
    }
  };

  const handleTouchEnd = (event: any) => {
    let numberOfCurrentTouches: number = 0;
    const video: any = document.getElementById("streamingVideoPlayer");
    const rect = video?.getBoundingClientRect();
    let client_x = 0;
    let client_y = 0;
    [...event.changedTouches].forEach((touch: any) => {
      client_x = (touch.clientX - rect?.left) / rect?.width;
      client_y = (touch.clientY - rect?.top) / rect?.height;
      numberOfCurrentTouches = touch.identifier;
    });
    touchInputReleased(client_x, client_y, numberOfCurrentTouches);
  };

  const handleMouseWheel = (e: any) => {
    scrollingMouseWheel(e.deltaY / 100);
  };

  function handleTouch(event: TouchEvent) {
    // event.preventDefault();
    const touches = event.touches;
    for (let i = 0; i < touches.length; i++) {
      const touch = touches[i];
      const x = touch.clientX;
      const y = touch.clientY;

      // console.log({ type: event.type, x, y, i });
      if (event.type === "touchstart") {
        Webrtc.sendTouchInput("Pressed", i, x, y);
      } else if (event.type === "touchmove") {
        Webrtc.sendTouchInput("Moved", i, x, y);
      } else if (event.type === "touchend") {
        Webrtc.sendTouchInput("Released", i, x, y);
      }
    }
  }
  useEffect(() => {
    const video: any = document.getElementById("streamingVideoPlayer");
    const uiOverlay: any = document.getElementById("ui-overlay");

    function positionUIOverlay() {
      const videoRect = video.getBoundingClientRect();
      uiOverlay.style.width = videoRect.width + "px";
      uiOverlay.style.height = videoRect.height + "px";
    }

    positionUIOverlay();
    const handlePointerLockChange = (event: any) => {
      if (document.pointerLockElement == null) mouseRelease(0, 0, 2);
    };

    document.addEventListener("pointerlockchange", handlePointerLockChange);
    window.addEventListener("resize", positionUIOverlay);

    return () => {
      // Clean up event listener when component unmounts
      document.removeEventListener(
        "pointerlockchange",
        handlePointerLockChange
      );
      window.removeEventListener("resize", positionUIOverlay);
    };
  });

  return (
    <VideoContainer
      // id="streamingVideo"
      // MOUSE HANDLER
      onMouseDown={(e) => handleMouseDown(e)}
      onMouseUp={(e) => handleMouseUp(e)}
      onMouseMove={(e) => handleMouseMove(e)}
      onContextMenu={(e) => {
        e.preventDefault();
        console.log("contex menu");
      }}
      onMouseLeave={(e) => handleMouseMove(e)}
      onPointerDown={(e) => onPointerDownElement(e)}
      onPointerUp={(e) => onPointerUpElement(e)}
      // TOUCH HANDLER
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
      onTouchStart={(e) => handleTouchStart(e)}
      onWheel={handleMouseWheel}
    >
      {/* <div className="ContentSectionTop">
                    <h2>Maximize The Screen</h2>
                </div> */}
      <VideoPlayer
        id="streamingVideoPlayer"
        // className="mobile_landscape"
        autoPlay
        loop
        muted
        playsInline={true}
        controls={false}
      />
      <UIOverlay id="ui-overlay">
        {isShow && !isMobile ? (
          <LaunchButton
            handleStopFetching={handleStopFetching}
            isMatched={isMatched}
            setIsMatched={setIsMatched}
            handleStartEmulator={handleStartEmulator}
            availableSlots={slotData.slots}
          />
        ) : isMobile ? (
          <MobileLandscape
            isFullScreen={isFullScreen}
            handleDisableMouseInteraction={handleDisableMouseInteraction}
          />
        ) : (
          <Home
            handleDisableMouseInteraction={handleDisableMouseInteraction}
            uiElementRef={uiElementRef}
          />
        )}
      </UIOverlay>
    </VideoContainer>
  );
};

export const Loader = () => {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

const LaunchButton = ({
  handleStartEmulator,
  setIsMatched,
  isMatched,
  availableSlots,
  handleStopFetching,
}: any) => {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 13 && isMatched) {
      event.preventDefault();
      handleStartEmulator();
    }
  };

  useEffect(() => {
    handleStopFetching(true);

    return () => {
      handleStopFetching(false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleStartEmulator, isMatched]);

  return (
    <>
      {
        <Modal
          handleStartEmulator={handleStartEmulator}
          setIsMatched={setIsMatched}
          availableSlots={availableSlots}
          isMatched={isMatched}
          className={`modal_outer ${!isMatched && "disable-launch-btn"}`}
        >
          <h4>
            <span>Launch</span>
          </h4>
        </Modal>
      }
    </>
  );
};

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover container */
`;

const UIOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  user-select: none;
  //   pointer-events: none; /* Allow clicks to go through the overlay to the video */
`;
