import { useActions } from "../../hooks/use-actions";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import {useState, useEffect} from "react";
import { 
    cameraRotation, 
    DollHouseView, 
    setDefaultCameraFromBackend, 
    roofViewMode 
} from "../../services/navbarServices";

const RoofViewHandler = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [dollIsOpen, setDollIsOpen] = useState(false)
    const { 
        setCameraIndex, 
        selectedComponentItem, 
        ActiveRoofView, 
        roofViewToggle 
    } = useActions();

    const { 
        defaultComponent 
    }:any = useTypedSelector(({ component }) => component);
      
    const { 
        defaultRoofViewState:DefaultState
    }:any = useTypedSelector(({ allscreens }) => allscreens);

    const { 
        cameraIndex:activeCamera
    } = useTypedSelector(({media}) => media);

    const { activeRoofView } = useTypedSelector(({ allscreens }) => allscreens)

    const handleRoofView = () => {
        roofViewToggle()
        if (!isOpen) {
            setCameraIndex(0);
            selectedComponentItem("");
            // roofviewMode("On")
            ActiveRoofView("Active")
        } else {
            ActiveRoofView("InActive")
            // roofviewMode("Off")
        }
        roofViewMode(isOpen)
    }

    const handleRotateCamera = (input: number) => {
        cameraRotation(input)
    }

    const handleDollHouseView = () => {
        // if (!dollIsOpen) {
        //     DollHouseView("On")
        // } else {
        //     DollHouseView("Off")
        // }
        DollHouseView(dollIsOpen)
    }

    useEffect(() => {
        if(activeRoofView !== "InActive") {
            setDefaultCameraFromBackend({
                CameraIndex:0
            });
        }
    },[activeRoofView])

    useEffect(() => {
        if(defaultComponent?.Type === 'Selected'){
            selectedComponentItem(defaultComponent.Component)
        }
    },[defaultComponent]);

    // When someone select the component from roofView it make roofView inActive
    // useEffect(() => {
    //     if (selectedComponentRoom) {
    //         setIsOpen(false)
    //         ActiveRoofView("InActive")
    //     }
    // }, [selectedComponentRoom])

    // When someone click to the surface from the roofView then inActivated the roomView 
    useEffect(() => {
        if (DefaultState?.State === "Off") {
            setIsOpen(false)
            ActiveRoofView("InActive");
        }else if(DefaultState?.State === "On"){
            setIsOpen(true);
            ActiveRoofView("Active");
        } 
    }, [DefaultState])

    // using this effect to inActive the roofView when someone select the camera
    useEffect(() => {
        if(activeCamera){
            setIsOpen(false)
            ActiveRoofView("InActive")
        } else if(activeCamera === undefined){
            // This Effect need camera index initially Active
            setIsOpen(true)
            ActiveRoofView("Active");
        }
    }, [activeCamera]);

    return {
        isOpen,
        activeRoofView,
        dollIsOpen,
        setIsOpen,
        setCameraIndex,
        selectedComponentItem,
        ActiveRoofView,
        roofViewToggle,
        handleRoofView,
        setDefaultCameraFromBackend,
        handleRotateCamera,
        handleDollHouseView,
        setDollIsOpen,
    }
}

export default RoofViewHandler;