import { ActionType } from "../action-types";
import { Action } from "../actions/component";


interface ComponentItem{
    selectedComponentRoom: string;
    // setComponentStateData: string;
    currentStateOfComponent: boolean | string;
    defaultComponent: string
    currentActiveComponent:string
}

export const INITIAL_STATE: ComponentItem = {
    selectedComponentRoom: '',
    currentStateOfComponent: '',
    defaultComponent:'',
    currentActiveComponent:""
    // setComponentStateData: '',
}

export default (state: ComponentItem = INITIAL_STATE, action: Action): ComponentItem => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.SELECTED_COMPONENT_ITEM:
            return { ...state, selectedComponentRoom: action.payload }
        case ActionType.SELECT_AND_UNSELECT_STATE:
            return { ...state, currentStateOfComponent: action.payload }
        case ActionType.DEFAULT_COMPONENT:
            return { ...state, defaultComponent: action.payload }
        case ActionType.CURRENT_ACTIVE_COMPONENT:
            return { ...state, currentActiveComponent: action.payload }
        default:
            return state;
    }
};