import { customUuid } from "../utility/utils";

declare var $: any;

const INPUT_KEY = 0;
const INPUT_MOUSE_BUTTON = 1;
const INPUT_MOUSE_AXIS = 2;
const INPUT_MOUSE_WHEEL = 3;
const INPUT_KEY_DOWN = 4;
const INPUT_KEY_UP = 5;

export class Webrtc {
  private static instance: Webrtc | null = null;
  private sentDataHistory: any[] = [];
  private lastInput: { id?: string; value?: object } = {};

  private addToHistory(data: any, id: string) {
    if (this.lastInput && this.lastInput.id) {
      const arr = ["Hovered", "Unhovered"];
      const type = JSON.parse(data)?.Component_Input?.Type;

      if (arr.includes(type)) return;

      if (this.sentDataHistory.length >= 20) {
        this.sentDataHistory.shift(); // Removeing the oldest entry
      }
      this.sentDataHistory.push(this.lastInput);
      this.lastInput = { value: JSON.parse(data), id };
      console.log(
        {
          n: JSON.parse(data),
          x: this.sentDataHistory,
        },
        "kishan",
        "addToHistory"
      );
    } else {
      this.lastInput = {
        value: JSON.parse(
          '{"C_Type":"WtoE","WtoE_Input":"Room","Room_Input":{"Room":"Living Room","RoomType":"Furnished"}}'
        ),
        id: customUuid(),
      };
    }
    // Limit the history size to 20 steps
  }

  ipAddress = localStorage.getItem("ipAddress");

  peerConnection: any;
  imageChannel: any;
  dataChannel: any;
  bufferedMessage: any;

  playerId: Number = -1;

  signalAddress: string = "";
  instanceId: string = "";

  imageBuffer: string = "";
  imageBufferSize: number = 0;

  connectedEvent: any = null;
  receivedEvent: any = null;
  errorEvent: any = null;

  signalingSocket: any = null;
  target: null | string = null;

  uuid: string = "";

  lastMouseX: number = 0;
  lastMouseY: number = 0;
  mouseMoveTimer: any = null;
  constructor(signalAddress: string, target: null | string) {
    this.signalAddress = signalAddress;
    this.target = target;
    this.init();
  }

  static connect(
    signalAddress: string,
    target: null | string,
    connectedEvent: any,
    receivedEvent: any,
    errorEvent: any
  ) {
    if (signalAddress.length === 0) {
      signalAddress = "demo.realityscale.com" + ":443/api/ws";
    }
    if (Webrtc.instance === null) {
      var inst = new Webrtc(signalAddress, target);

      inst.connectedEvent = connectedEvent;
      inst.receivedEvent = receivedEvent;
      inst.errorEvent = errorEvent;

      Webrtc.instance = inst;
    }
  }

  static sendJSON(data: any) {
    if (Webrtc.instance === null) return;

    Webrtc.instance._sendJSON(data);
  }

  static sendData(data: string) {
    if (Webrtc.instance === null) return;

    Webrtc.instance._sendData(data, customUuid());
  }
  // static sendMousePosition(x: number, y: number) {
  //   if (Webrtc.instance === null) return;
  //   if (Webrtc.instance.dataChannel == null) return;
  //   if (Webrtc.instance.mouseMoveTimer) {
  //     clearTimeout(Webrtc.instance.mouseMoveTimer);
  //   }
  //   let buffer = new Int8Array(new ArrayBuffer(9));
  //   buffer[0] = INPUT_MOUSE_AXIS << 1;
  //   Webrtc.instance.bufferedMessage = buffer;

  //   Webrtc.instance.mouseMoveTimer = setTimeout(() => {
  //     Webrtc.setFloatBytes(x, buffer, 1);
  //     Webrtc.setFloatBytes(y, buffer, 5);
  //     Webrtc?.instance?.dataChannel.send(Webrtc.instance.bufferedMessage);
  //   }, 33);
  // }

  static sendMousePosition(x: number, y: number) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    let buffer = new Int8Array(new ArrayBuffer(9));

    buffer[0] = INPUT_MOUSE_AXIS << 1;

    Webrtc.setFloatBytes(x, buffer, 1);
    Webrtc.setFloatBytes(y, buffer, 5);

    Webrtc.instance.dataChannel.send(buffer);
  }
  static sendMouseButton(button: number, down: boolean) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    var buffer = new Int8Array(new ArrayBuffer(2));

    buffer[0] = (INPUT_MOUSE_BUTTON << 1) | (down ? 1 : 0);
    buffer[1] = button;

    Webrtc.instance.dataChannel.send(buffer);
  }
  static sendKey(key: number, down: boolean) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    var buffer = new Int8Array(new ArrayBuffer(2));

    buffer[0] = (INPUT_KEY << 1) | (down ? 1 : 0);
    buffer[1] = key;

    Webrtc.instance.dataChannel.send(buffer);
  }
  static sendMouseWheel(delta: number) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    var buffer = new Int8Array(new ArrayBuffer(2));

    buffer[0] = INPUT_MOUSE_WHEEL << 1;
    buffer[1] = delta;

    Webrtc.instance.dataChannel.send(buffer);
  }
  static setFloatBytes(val: number, target: any, offset: number) {
    let array = new Float32Array(new ArrayBuffer(4));
    array[0] = val;

    let int8Array = new Int8Array(array.buffer);

    for (let i = 0; i < 4; i++) {
      target[i + offset] = int8Array[i];
    }
  }
  static sendAxisData(x: number, y: number) {
    if (Webrtc.instance === null) return;

    if (Webrtc.instance.dataChannel != null) {
      let f = 10;
      let deltaX: number = Math.floor(
        (x - Webrtc.instance.lastMouseX) * f * 127
      );
      let deltaY: number = Math.floor(
        (y - Webrtc.instance.lastMouseY) * f * 127
      );

      Webrtc.instance.lastMouseX = x;
      Webrtc.instance.lastMouseY = y;

      let buffer = new Int8Array(new ArrayBuffer(3));

      buffer[0] = 2 << 1;
      buffer[1] = deltaX;
      buffer[2] = deltaY;

      Webrtc.instance.dataChannel.send(buffer);
    }
  }
  static sendBinaryInput(key: number, type: number, payload: number) {
    if (Webrtc.instance === null) return;

    if (Webrtc.instance.dataChannel != null) {
      var buffer = new Int8Array(new ArrayBuffer(2));

      buffer[0] = (key << 1) | type;
      buffer[1] = payload;

      Webrtc.instance.dataChannel.send(buffer);
    }
  }

  static sendKeyboardInput(key: string, isKeyDown: boolean) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    const buffer = new Int8Array(new ArrayBuffer(2));

    if (["w", "a", "s", "d"].includes(key.toLowerCase())) {
      buffer[0] = (isKeyDown ? INPUT_KEY_DOWN : INPUT_KEY_UP) << 1;
      buffer[1] = key.toLowerCase().charCodeAt(0);
    } else if (
      ["arrowup", "arrowdown", "arrowleft", "arrowright"].includes(
        key.toLowerCase()
      )
    ) {
      buffer[0] = (isKeyDown ? INPUT_KEY_DOWN : INPUT_KEY_UP) << 1;
      buffer[1] =
        key.toLowerCase() === "arrowup"
          ? 38 // Up
          : key.toLowerCase() === "arrowdown"
          ? 40 // Down
          : key.toLowerCase() === "arrowleft"
          ? 37 // Left
          : key.toLowerCase() === "arrowright"
          ? 39
          : 0; // Right
    }

    Webrtc.instance.dataChannel.send(buffer);
  }
  static sendTouchInput(state: string, index: number, x: number, y: number) {
    if (Webrtc.instance === null) return;
    if (Webrtc.instance.dataChannel == null) return;

    const buffer = new Int8Array(new ArrayBuffer(9));

    buffer[0] = INPUT_MOUSE_AXIS << 1;

    Webrtc.setFloatBytes(x, buffer, 1);
    Webrtc.setFloatBytes(y, buffer, 5);

    Webrtc.instance.dataChannel.send(buffer);
  }
  static undo() {
    if (Webrtc.instance === null || !Webrtc.instance.sentDataHistory.length)
      return;
    const undoneData = Webrtc.instance.sentDataHistory.at(-1);
    console.log(undoneData);

    Webrtc.instance.sentDataHistory = Webrtc.instance.sentDataHistory.slice(
      0,
      -1
    );
    Webrtc.instance._sendData(JSON.stringify(undoneData.value), "");
  }

  private init() {
    if (this.peerConnection != null) {
      return;
    }
    var self = this;
    const configuration = { iceServers: [] };
    this.peerConnection = new RTCPeerConnection(configuration);

    this.peerConnection.onicecandidate = function (event: any) {};

    this.peerConnection.ondatachannel = function (event: any) {
      self.onDataChannel(event);
    };

    this.peerConnection.ontrack = this.onTrack;
    this.peerConnection.onerror = function (event: any) {
      console.log("WebRTC Error", event);

      self.onStreamError("WebRTC error");
    };
    this.peerConnection.onclose = function (event: any) {};
    this.peerConnection.onremovestream = function (event: any) {};

    this.peerConnection.onconnectionstatechange = () => {
      console.log(
        "onconnectionstatechange",
        this.peerConnection.connectionState
      );

      if (this.peerConnection.connectionState === "disconnected") {
        console.log("Disconnected!");

        self.onDisconnected();
      }
    };

    this.sendStartStream();
  }

  private _sendJSON(data: any) {
    console.log();
    console.log(JSON.stringify(data), ".......................");
    this._sendData(JSON.stringify(data), customUuid());
  }

  private _sendData(data: string, historyId: string) {
    if (
      historyId?.length &&
      !this.sentDataHistory.find((x) => x.id === historyId)
    ) {
      this.addToHistory(data, historyId); // sent data to the history
    }
    if (this.dataChannel != null) {
      this.dataChannel.send(data);
    } else {
      this.bufferedMessage = data;
    }
  }
  /*
   * Called when the main data channel is opened, and data can be send to the server
   */
  private onDataChannelOpened() {
    if (this.connectedEvent) {
      this.connectedEvent(this.uuid);
    }
  }
  /*
   * Called when the server has send an image
   *
   * @param imageData  base64 encoded png image
   */
  private onImageReceived(imageData: string) {
    //This is a way to display and base64 encoded image
    //$("#imageTest")[0].src = "data:image/png;base64, " + imageData;
    //const objectURL = URL.createObjectURL(new Blob([imageData]));

    // Create an HTML anchor element
    const anchor = document.createElement("a");
    anchor.href = "data:image/png;base64, " + imageData;
    anchor.download = "image.png";

    // Append the anchor element to the body
    document.body.appendChild(anchor);

    // Click the anchor element to download the image
    anchor.click();

    // Remove the anchor element from the body
    document.body.removeChild(anchor);

    // Revoke the object URL
    //URL.revokeObjectURL(objectURL);
  }
  /*
   * Called when the server closing the connection
   */
  private onDisconnected() {
    //	window.location.reload();
  }

  private onStreamError(errorMesssage: string) {
    console.error(errorMesssage);
  }
  private onDataChannel(event: any) {
    var self = this;
    var label = event.channel.label;

    //console.log("Datachannel: " + label);
    //console.log(event.channel)

    if (label === "ImageChannel") {
      this.imageChannel = event.channel;
      this.imageChannel.onopen = function () {
        self.imageChannel.onmessage = function (event: any) {
          var message: string = event.data;

          //Header message format: ":imageSize:"
          if (message.startsWith(":")) {
            self.imageBuffer = "";
            self.imageBufferSize = parseInt(
              message.substring(1, message.length - 1)
            );

            console.log("Start receiving image", self.imageBufferSize);
          } else {
            self.imageBuffer += message;

            if (self.imageBuffer.length === self.imageBufferSize) {
              console.log("Image received");

              var imageData: string = self.imageBuffer;
              self.imageBuffer = "";
              self.onImageReceived(imageData);
            }
          }
        };
      };
    } else {
      this.dataChannel = event.channel;

      this.dataChannel.onopen = function () {
        //console.log("datachannel open ");
        if (self.bufferedMessage != null) {
          //console.log(self.bufferedMessage)
          self.dataChannel.send(self.bufferedMessage);
        }
        // printing compass data
        self.dataChannel.onmessage = function (event: any) {
          var message: any = event.data;
          //console.log(message);

          if (self.receivedEvent) {
            self.receivedEvent(message);
          }
        };

        self.onDataChannelOpened();
      };
    }

    event.channel.onclose = function () {
      //console.log("datachannel close");
    };
  }

  private async sendStartStream() {
    var self = this;

    console.log("Send start");
    console.log(self.signalAddress);

    if (this.signalAddress) {
      this.createSignalingSocket(this.signalAddress, this.target);
    }
  }

  private receiveSdp(offer: any) {
    this.uuid = offer.uuid;

    var sdpData = this.replaceAll(offer.sdp);

    //console.log("Offer: " + sdpData);
    var self = this;

    var desc = {
      type: <RTCSdpType>"offer",
      sdp: sdpData,
    };
    this.peerConnection
      .setRemoteDescription(new RTCSessionDescription(desc))
      .then(() => {
        offer.candidates.forEach((c: string) => {
          var candidateData = c.split(";");

          var candidate: any = {
            sdpMid: candidateData[0],
            sdpMLineIndex: candidateData[1],
            candidate: candidateData[2],
          };

          this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
        });

        this.createAnswer();
      })
      .catch(function (e: any) {
        console.log("setRemoteDescription error", e);
      });
  }

  private createAnswer() {
    this.peerConnection.createAnswer().then((desc: any) => {
      if (!desc.sdp.includes("H264")) {
        this.onStreamError("Codec Error!");
        this.peerConnection.close();
        return;
      }

      this.peerConnection.setLocalDescription(desc);
      this.signalingSendAnswer(desc);
    });
  }
  private onError(error: any) {
    this.errorEvent(error.message);
  }

  private onTrack(e: any) {
    //var stream = e.streams[0];
    var video: any = document.getElementById("streamingVideoPlayer");

    console.log("onTrack", e.streams);
    if (video.srcObject !== e.streams[0]) {
      video.srcObject = e.streams[0];
    }
  }
  private disconnect() {
    if (this.peerConnection == null) {
      return;
    }

    this.peerConnection.close();
    this.peerConnection = null;
  }

  private replaceAll(source: string) {
    var out = source;
    while (source.indexOf("\\n\\r") >= 0) {
      out = out.replace("\\n\\r", "\r\n");
      //out = out.replace("\\n\\r", "\r");
    }

    return out;
  }

  private createSignalingSocket(
    siganlingAdress: string,
    target: null | string
  ) {
    let wsProtocol = "wss";
    if (window.location.protocol.startsWith("https")) {
      wsProtocol = "wss";
    }
    //console.log("signaling  ", wsProtocol);
    this.signalingSocket = new WebSocket(
      wsProtocol + "://" + siganlingAdress + "/",
      "signaling"
    );

    this.signalingSocket.onerror = () => {
      console.log("Connection Error");
    };

    this.signalingSocket.onopen = () => {
      console.log("WebSocket Client Connected");

      var request = {
        type: "new",
        target: target,
      };

      this.signalingSocket.send(JSON.stringify(request) + "\r\n\r\n");
    };

    this.signalingSocket.onclose = () => {
      console.log("Signaling SocketClosed");
      this.signalingSocket = null;
    };

    var receiveBuffer = "";
    this.signalingSocket.onmessage = (e: any) => {
      receiveBuffer += e.data;
      var index = -1;
      var seperator = "\r\n\r\n";

      if ((index = receiveBuffer.indexOf(seperator)) > -1) {
        var data = receiveBuffer.substring(0, index);
        receiveBuffer = receiveBuffer.substring(index + seperator.length);

        var jsonData = JSON.parse(data);

        if (jsonData.command === "sdp") {
          this.receiveSdp(jsonData);
        } else {
          this.onError(jsonData);
        }
      }
    };
  }

  private signalingSendAnswer(desc: any) {
    if (!this.signalingSocket) {
      return;
    }

    var answerJson = {
      type: "sdp",
      sdp: desc.sdp,
    };
    this.signalingSocket.send(JSON.stringify(answerJson) + "\r\n\r\n");
  }
}
