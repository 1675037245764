

function TimeIcons2({ width, height, fill, viewBox }:any) {
    return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7984 9.00017C12.7984 11.0972 11.0985 12.7971 9.00151 12.7971C6.90454 12.7971 5.20459 11.0971 5.20459 9.00017C5.20459 6.9032 6.90454 5.20325 9.00151 5.20325C11.0985 5.20325 12.7984 6.9032 12.7984 9.00017Z" stroke={fill} strokeWidth="1.375"/>
<path d="M9.00089 2.81254V0V2.81254Z" fill={fill}/>
<path d="M9.00089 2.81254V0" stroke={fill} strokeWidth="1.375"/>
<path d="M9.00089 18.0003V15.1877V18.0003Z" fill={fill}/>
<path d="M9.00089 18.0003V15.1877" stroke={fill} strokeWidth="1.375"/>
<path d="M15.1873 9.00024H17.9998H15.1873Z" fill={fill}/>
<path d="M15.1873 9.00024H17.9998" stroke={fill} strokeWidth="1.375"/>
<path d="M-4.76837e-06 9.00024H2.81253H-4.76837e-06Z" fill={fill}/>
<path d="M-4.64916e-06 9.00024H2.81253" stroke={fill} strokeWidth="1.375"/>
<path d="M13.3753 4.62461L15.364 2.63586L13.3753 4.62461Z" fill={fill}/>
<path d="M13.3753 4.62461L15.364 2.63586" stroke={fill} strokeWidth="1.375"/>
<path d="M2.63455 15.3641L4.62329 13.3754L2.63455 15.3641Z" fill={fill}/>
<path d="M2.63455 15.3641L4.62329 13.3754" stroke={fill} strokeWidth="1.375"/>
<path d="M13.3753 13.3754L15.364 15.3641L13.3753 13.3754Z" fill={fill}/>
<path d="M13.3753 13.3754L15.364 15.3641" stroke={fill} strokeWidth="1.375"/>
<path d="M2.63555 2.63601L4.6243 4.62476L2.63555 2.63601Z" fill={fill}/>
<path d="M2.63555 2.63601L4.6243 4.62476" stroke={fill} strokeWidth="1.375"/>
</svg>
  );
  }
  
  export default TimeIcons2;