import { Webrtc } from "./webrtc";

export const SwitchingModeRequest = async (mode:string) =>{
    const modeData = {
        "C_Type" : "WtoU",
        "WtoU_Input" :  "Mode",
        "Mode_Input" : 
        {
        "Mode": mode
        }
        }
        
    console.log("***modeData***",modeData)
    Webrtc.sendJSON(modeData);
}


export const ThemeModeChange = (item:string) => {
    const themeModeJson={
        "C_Type" : "WtoE",
        "WtoE_Input" :  "State",
        "State_Input" :
        {
        "State" :`${item}`
        }
        }
        Webrtc.sendJSON(themeModeJson);
        console.log("***ThemeMode***", themeModeJson)
}


export const ChangeDayNightMode = (time:number) => {
    const mode = {
        "C_Type" : "WtoE",
        "WtoE_Input" :  "Time",
        "Time_Input" :
        {
            "TimeAlpha" : time,
        }
    }
    Webrtc.sendJSON(mode);
    console.log("day night mode", mode); 
}