import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";
import { isMobile } from "react-device-detect";
import styled from "styled-components";




const ToolTextPermissionBox = ({setStartHelpTutorial, setShowHelpSection, handleDisableMouseInteraction}:any) => {
    return (
      <>
         <OverlayWrapper show={true}>
        </OverlayWrapper>
        <div className={`help_icon ${isMobile && 'help_icon_mobile'}`}>
        <HelpLeftIcon />
        </div>
          <div className={`go_through_popup ${isMobile && 'go_through_popup_mobile'}`}>
          <div className="popup_inner">
          {/* <span data-tour-elem="badge" className="badge">!</span> */}
            <p>Do you want to go through with Help Section?</p>
            <div className="yes_no_btn">
            <button onClick={() => {setStartHelpTutorial(true); setShowHelpSection(false); handleDisableMouseInteraction(true)}} className="yes_btn">Yes</button>
            <button onClick={() => {setStartHelpTutorial(false); setShowHelpSection(false); handleDisableMouseInteraction(false)}} className="yes_btn">No</button>
            </div>
            </div>
          </div>
      </>
     
    );
  }


export default ToolTextPermissionBox;


// ToolTextPermissionBox.js

// import React from "react";
// import styled from "styled-components";
// import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";
// import { isMobile } from "react-device-detect";

const OverlayWrapper:any = styled.div`
  display: ${({ show }:any) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

// const PopupWrapper = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: white;
//   padding: 20px;
//   border-radius: 5px;
//   z-index: 1000;
// `;

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const ToolTextPermissionBox = ({ setStartHelpTutorial, setShowHelpSection, show }:any) => {
//   return (
//     <>
//       <OverlayWrapper show={true}>
//         <PopupWrapper>
//           <div className="popup_inner">
//             {/* <span data-tour-elem="badge" className="badge">!</span> */}
//             <p>Do you want to go through with the Help Section?</p>
//             <ButtonWrapper className="yes_no_btn">
//               <button onClick={() => { setStartHelpTutorial(true); setShowHelpSection(false) }} className="yes_btn">Yes</button>
//               <button onClick={() => { setStartHelpTutorial(false); setShowHelpSection(false) }} className="yes_btn">No</button>
//             </ButtonWrapper>
//           </div>
//         </PopupWrapper>
//       </OverlayWrapper>

//       <div className={`help_icon ${isMobile && 'help_icon_mobile'}`}>
//         <HelpLeftIcon />
//       </div>
//     </>
//   );
// }

// export default ToolTextPermissionBox;
