

function RoofViewSvgIcons({ width, height, fill, viewBox, rotation }:any) {
    return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
        <path d="M0 0V28H19.9195V14.8456H28V0H0ZM10.6738 1.65376V3.57047H3.90873L2.14228 1.65376H10.6738ZM10.6738 4.32208V13.1167L4.09664 13.117V4.32234L10.6738 4.32208ZM1.65369 2.21745L3.3449 4.05913V13.1168H1.65369V2.21745ZM1.65369 14.8081H3.3449V23.7154L1.65369 25.7448V14.8081ZM2.14236 26.3087L3.94632 24.1289H16.6873L17.8148 26.3087H2.14236ZM18.2283 25.4819L17.4767 24.0161L17.4764 23.3772V14.8081H18.228L18.2283 25.4819ZM26.3088 13.1544H23.6028V9.69664L26.3088 8.64429V13.1544ZM26.3088 6.57718H23.6028V4.20933L26.3088 2.1047V6.57718ZM12.3276 1.65376H25.6699L23.1893 3.57047H12.3275L12.3276 1.65376ZM12.3276 4.32208H22.8511V6.53946H12.3276V4.32208ZM12.3276 8.23094H25.294L23.6403 8.90732V8.86983H12.3651L12.3654 8.23093L12.3276 8.23094ZM12.3276 9.58396H22.8511V13.1544H12.3276V9.58396ZM16.7249 14.8081V23.3772H4.09673V14.8081H16.7249Z" fill={fill}/>
    </svg>
    );
  }
  
  export default RoofViewSvgIcons;