function TimeIcons3({ width, height, fill, viewBox }:any) {
    return (
<svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.64298 2.57135V0.64283C9.64298 0.413101 9.52044 0.200887 9.32156 0.0860946C9.12268 -0.0286982 8.8776 -0.0286982 8.67872 0.0860946C8.47984 0.200887 8.3573 0.413109 8.3573 0.64283V2.57135C8.3573 2.80108 8.47984 3.01329 8.67872 3.12808C8.8776 3.24288 9.12268 3.24288 9.32156 3.12808C9.52043 3.01329 9.64298 2.80107 9.64298 2.57135Z" fill={fill}/>
<path d="M9.64298 17.3566V15.4281C9.64298 15.1984 9.52044 14.9862 9.32156 14.8714C9.12268 14.7566 8.8776 14.7566 8.67872 14.8714C8.47984 14.9862 8.3573 15.1984 8.3573 15.4281V17.3566C8.3573 17.5864 8.47984 17.7986 8.67872 17.9134C8.8776 18.0282 9.12268 18.0282 9.32156 17.9134C9.52043 17.7986 9.64298 17.5863 9.64298 17.3566Z" fill={fill}/>
<path d="M0 8.99977C0 9.17024 0.0677296 9.33382 0.18826 9.45435C0.308791 9.57488 0.472373 9.64261 0.642839 9.64261H2.57136C2.80109 9.64261 3.0133 9.52007 3.12809 9.32119C3.24289 9.12231 3.24289 8.87723 3.12809 8.67835C3.0133 8.47948 2.80108 8.35693 2.57136 8.35693H0.642839C0.472373 8.35693 0.308791 8.42466 0.18826 8.54519C0.0677296 8.66572 0 8.82931 0 8.99977Z" fill={fill}/>
<path d="M17.3574 8.35693H15.4289C15.1992 8.35693 14.987 8.47947 14.8722 8.67835C14.7574 8.87723 14.7574 9.12232 14.8722 9.32119C14.987 9.52007 15.1992 9.64261 15.4289 9.64261H17.3574C17.5871 9.64261 17.7994 9.52007 17.9142 9.32119C18.0289 9.12231 18.0289 8.87723 17.9142 8.67835C17.7994 8.47948 17.5871 8.35693 17.3574 8.35693Z" fill={fill}/>
<path d="M3.54225 2.63568C3.37652 2.5031 3.15596 2.46077 2.95293 2.52276C2.74989 2.58475 2.59062 2.74302 2.52719 2.94564C2.46376 3.1481 2.50437 3.36892 2.63581 3.53565L3.9987 4.91129C4.11995 5.03168 4.28425 5.09869 4.45501 5.09769C4.6259 5.09869 4.7902 5.03168 4.91146 4.91129C5.03314 4.79061 5.10159 4.62632 5.10159 4.45484C5.10159 4.28351 5.03314 4.11921 4.91146 3.99853L3.54225 2.63568Z" fill={fill}/>
<path d="M14.0013 13.0882C13.8383 12.9251 13.6007 12.8615 13.3778 12.9212C13.1551 12.9807 12.981 13.1548 12.9215 13.3775C12.8618 13.6003 12.9254 13.838 13.0885 14.001L14.4577 15.3639C14.5775 15.4827 14.7391 15.5496 14.9077 15.5503C15.0786 15.5513 15.2429 15.4843 15.3641 15.3639C15.4838 15.2435 15.5511 15.0805 15.5511 14.9107C15.5511 14.7408 15.4838 14.578 15.3641 14.4574L14.0013 13.0882Z" fill={fill}/>
<path d="M3.54132 15.3639L4.9105 14.001C5.07365 13.838 5.13721 13.6004 5.07752 13.3775C5.01797 13.1548 4.84392 12.9807 4.62122 12.9212C4.39838 12.8615 4.16076 12.9251 3.99774 13.0882L2.63484 14.4639C2.51517 14.5843 2.44788 14.7473 2.44788 14.917C2.44788 15.0869 2.51517 15.2498 2.63484 15.3703C2.75609 15.4905 2.92039 15.5577 3.0913 15.5567C3.26076 15.5543 3.42261 15.485 3.54128 15.3638L3.54132 15.3639Z" fill={fill}/>
<path d="M14.0003 4.9113L15.3632 3.53566C15.5063 3.42116 15.5937 3.25112 15.6036 3.06815C15.6133 2.88506 15.5446 2.7067 15.4146 2.57758C15.2844 2.44844 15.1055 2.38099 14.9227 2.39204C14.7397 2.40309 14.5703 2.49177 14.4568 2.63569L13.0876 3.99858C12.9659 4.11926 12.8975 4.28355 12.8975 4.45489C12.8975 4.62636 12.9659 4.79066 13.0876 4.91134C13.2088 5.03173 13.3731 5.09874 13.544 5.09773C13.7148 5.09874 13.8791 5.03173 14.0003 4.91134L14.0003 4.9113Z" fill={fill}/>
<path d="M3.85706 8.99977C3.85706 10.3637 4.39888 11.6717 5.36328 12.6363C6.32784 13.6007 7.63589 14.1425 8.99977 14.1425C10.3637 14.1425 11.6717 13.6007 12.6363 12.6363C13.6007 11.6717 14.1425 10.3637 14.1425 8.99977C14.1425 7.63589 13.6007 6.32784 12.6363 5.36328C11.6717 4.39888 10.3637 3.85706 8.99977 3.85706C7.63589 3.85706 6.32784 4.39888 5.36328 5.36328C4.39888 6.32784 3.85706 7.63589 3.85706 8.99977ZM8.99977 5.14273C9.9504 5.14661 10.8662 5.50146 11.5711 6.13914C11.4647 6.12206 11.3573 6.1113 11.2497 6.10699C10.2163 6.10699 9.2612 6.65829 8.74451 7.55338C8.22781 8.44847 8.22781 9.55107 8.74451 10.4462C9.2612 11.3412 10.2163 11.8925 11.2497 11.8925C11.3568 11.8989 11.4641 11.8989 11.5711 11.8925C10.6224 12.7292 9.32361 13.0475 8.0955 12.7443C6.86734 12.4411 5.86595 11.5549 5.41567 10.3728C4.96538 9.1906 5.12323 7.8629 5.8384 6.81944C6.55342 5.77584 7.73477 5.14935 8.99977 5.14273Z" fill={fill}/>
</svg>
  );
  }
  
  export default TimeIcons3;
