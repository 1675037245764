import { combineReducers } from 'redux';
import NavbarReducer from './NavbarReducer';
import ScreenReducer from './ScreenReducer';
import AllAppReducer from './AllAppReducer';
import MediaReducer from './MediaReducer';
import FooterNavbarReducer from './FooterNavbarReducer';
import Mode from './ModeReducer';
import CompassReducer from './CompassReducer';
import ComponentReducer from './ComponentReducer';
import ThemeModeReducer from './ThemeModeReducer';
import MobileReducers from './MobileReducers';
import ComponentDescriptionModalReducer from './ComponentDescriptionModalReducer';
import buildingReducer from './BuildingReducer';
import MainScreenReducer from './MainScreenReducer';

const reducers = combineReducers({
      navbar: NavbarReducer,
      allscreens: ScreenReducer,
      allapp: AllAppReducer,
      media: MediaReducer,
      footernavbar: FooterNavbarReducer,
      mode:Mode,
      compass: CompassReducer,
      component: ComponentReducer,
      themeMode: ThemeModeReducer,
      mobileReducer: MobileReducers,
      descriptionModal:ComponentDescriptionModalReducer,
      buildingReducer:buildingReducer,
      main_screen: MainScreenReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;