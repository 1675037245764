import { useState } from "react";
import { BuildingList } from "../Types";
import NavbarSvgIcons from "./NavbarSvgIcons";
import { StateActionTypes } from "../ComponentStateService/ItemTypes";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";




const TypeOfBuildingComponent = (props: any) => {

    const {
        index,
        active,
        dispatch,
        navbarOpen,
        arrow_left,
        isToggleOpen,
        allBuildingData,
        handleSelectBuilding,
        handleUpdateToggleState,
        helpButtonToggle
    } = props

    const [isHover, setIsHover] = useState(false);

    return (
        <li
            className={`dropdown_li_item step step-2 ${navbarOpen === 'building' && isToggleOpen[index] && 'activee'}`}
            onClick={(data) => { dispatch({ type: StateActionTypes.UPDATE_NAVBAR_STATE, currentNavbarState: data.currentTarget.id }); handleUpdateToggleState(index) }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            id="building"
        >
            <div className="dropdown-left">
                <div className="imgArrow">
                    <NavbarSvgIcons
                        rotation={navbarOpen === 'building' && isToggleOpen[index] ? "M8.5 15L2 8.5L8.5 2" : "M2 15L8.5 8.5L2 2"}
                        width="10"
                        height="17"
                        viewBox="0 0 10 17"
                        fill={navbarOpen === 'building' && isToggleOpen[index] || isHover ? "#3A3A3A" : "white"}
                    />
                </div>
            </div>
            <div className="dropdown_building" id="navbarDropdown">
                Type of Building
            </div>
            {isHover && helpButtonToggle && 
                <HelpSection 
                    text="Select property type" 
                    iconSpace="" 
                    className="type_text" 
                    icon={<HelpRightIcon />}
                />
            }
            {isToggleOpen[index] && navbarOpen === 'building' && <div className="dropdown_toggle1">
                <ul className="navbar-nav mr-auto">
                    {allBuildingData.BuildingList.map((buildingTypeList: BuildingList, index: number) => {
                        const buildingTypeName = buildingTypeList.BuildingType.Name
                        return <li
                            key={index}
                            data-value={index}
                            className={active === buildingTypeName ? `${allBuildingData.BuildingList.length === 1 ? '' : 'dropdown-toggle2'} dropdown-active` : `dropdown-toggle2`}
                            data-name={buildingTypeName}
                            onClick={(e) => { handleSelectBuilding(e); setIsHover(false) }}
                        >
                            {buildingTypeList.BuildingType.Name}
                        </li>
                    })}
                </ul>
            </div>}
        </li>
    );
}



export default TypeOfBuildingComponent;
