import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect";

const StartApplicationByPass = ({
  setIsMatched,
  isMatched,
  availableSlots,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const [isMatching, setIsMatching] = useState<boolean>(false);

  const inputRef: any = useRef(null);
  const items = ["digixito_demo", "ascendance", "snakescript", "yoctotta", "z"];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsMatching(true);

    // Filter the items for exact word match
    const matches = items.filter(
      (item) => item.toLowerCase() === value.toLowerCase()
    );

    setTimeout(() => {
      setIsMatching(false);
      setIsMatched(matches.length > 0);
    }, 500);
  };

  useEffect(() => {
    localStorage.setItem("pass", inputValue);
  }, [isMatched]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <>
      <Container>
        <InputOuter>
          <StyledInput
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={`Enter Access Key`}
            isMatching={isMatching}
            isMatched={isMatched}
            // autoFocus={true}
            ref={inputRef}
            id="launch_app"
          />
          {isMatched ? (
            <SuccessIcon />
          ) : (
            <CrossIcon setInputValue={setInputValue} />
          )}
        </InputOuter>
        {availableSlots === 0 && (
          <div className="slot_not_available">
            <h2>Sorry, but all slots are currently filled up.</h2>
          </div>
        )}
      </Container>
    </>
  );
};

export default StartApplicationByPass;

const CrossIcon = ({ setInputValue }: any) => {
  return (
    <svg
      onClick={() => setInputValue("")}
      className="cross-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17 17L1 1M17 1L1 17"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      className="check-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
    >
      <path
        d="M0.75 4.75L4.25 8.25L11.25 0.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Container = styled.div`
  position: absolute;
  // top: 42%;
  top: ${isMobile ? "38%" : "42%"};
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 40px;
  border: unset;
  border-radius: 10px;
  z-index: 9999999;
`;

const InputOuter = styled.div`
  position: relative;
  & svg.cross-icon {
    position: absolute;
    right: 10px;
    top: 20%;
    background: #f9d412;
    padding: 4px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    // transition: all 0.3s ease;
  }
  & svg.check-icon {
    position: absolute;
    right: 10px;
    top: 20%;
    background: rgb(38 180 0);
    padding: 4px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    // transition: all 0.2s ease-in-out;
    & path {
      stroke: #fff;
    }
  }
`;

const StyledInput = styled.input<{ isMatching: boolean; isMatched: boolean }>`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0;
  width: 300px;

  ${({ isMatching }) =>
    isMatching &&
    `
    background-color: #f2f2f2;
  `}

  ${({ isMatched }) =>
    isMatched &&
    `
    border-color: green;
  `}
  &:focus {
    outline: none; /* Remove the focus outline */
  }
`;
