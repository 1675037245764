import { useActions } from "../../../hooks/use-actions";
import { autoModeRequest } from "../../../services/media";
import { useTypedSelector } from "../../../hooks/use-typed-selector";

const MobileMode = () => {

    const { CurrentModeChangeAction } = useActions();
    const { changeCurrentMode } = useTypedSelector(({ mode }) => mode)

    const handleModeChange = (id: string) => {
        CurrentModeChangeAction(id)
        if(id === "Auto") {
            autoModeRequest(id)
        }
    }

    return <>
    <div className="drgee step step-9">
       <div id="Manual" onClick={(e) => { handleModeChange(e.currentTarget.id) }} className={`drgee_view ${changeCurrentMode == "Manual" && "active_view"}`}>
           <img src={`/images/svgIcons/${changeCurrentMode == "Manual" ? 'ManualBlack' : 'ManualMode'}.svg`} alt="img" />
       </div>
       <div id="Auto" onClick={(e) => { handleModeChange(e.currentTarget.id) }} className={`drgee_view automatic_view ${changeCurrentMode == "Auto" && "active_view"}`}>
           <img src={`/images/svgIcons/${changeCurrentMode == "Auto" ? 'AutoBlack' : 'AutoMode'}.svg`} alt="img" />
       </div>
   </div>
    </>
}


export default MobileMode;