interface ColorType {
  color: string | null;
  className: {
    row: string;
    color_section: string;
    col_md_02: string;
    col_md_10: string;
    fabric_color: string;
  };
}

export const Color = (props: ColorType) => {
  return (
    // <div className={props.className.divTag}>
    //     <h4>Colors</h4>
    //     <p className={props.className.pTag}>{props.color}</p>
    // </div>
    <div className={props.className.row}>
      <div className={props.className.color_section}>
        <div className={props.className.col_md_02}>
          <h3>Color</h3>
        </div>
        <div className={props.className.col_md_10}>
          <div className={props.className.fabric_color}>
            {" "}
            <span></span> {props.color}
          </div>
        </div>
      </div>
    </div>
  );
};
