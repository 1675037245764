

const DisplayToggleVisibility = ({ handleShowContent, isShow }:any) => (
    <div className='Expand_toggle'>
       <div className='Expand_invisible'>
        <p>Show/Hide UI </p>
        <label className='switch'>
          <input onClick={handleShowContent} type="checkbox" defaultChecked={isShow && true}/>
          <span className="slider round"></span>
        </label>
        </div>
    </div>
)


export default DisplayToggleVisibility;