export enum ActionType {
    COMPASS_DATA="compass_data",
    SCREEN_IMAGE = "screen_image",
    ALL_APP = "all_app",
    PLAY_PAUSE_STATUS = "play_pause_status",
    NEXT_ITEM = "next_item",
    PREVIOUS_ITEM = "previous_item",
    BUILDING_DATA = "building_data",
    ROOM = "room",
    SELECTED_ROOM_TYPE = "selected_room_type",
    // MODE = "mode",
    COMPASS="compass",
    DEFAULT_CAMERA_INDEX="default_camera_index",
    DEFAULT_ROOM_TYPE="default_room_type",
    SELECTED_ROOMTYPE_INDEX="selected_roomtype_index",
    GET_STATE_RESPONSE="GET_STATE_RESPONSE",
    DEFAULT_ROOM="DEFAULT_ROOM",
    SELECTED_COMPONENT_ITEM="SELECTED_COMPONENT_ITEM",
    GET_COMPONENT_STATE_DATA="GET_COMPONENT_STATE_DATA",
    SELECT_AND_UNSELECT_STATE="SELECT_AND_UNSELECT_STATE",
    THEME_MODE="THEME_MODE",
    DEFAULT_COMPONENT="DEFAULT_COMPONENT",
    CURRENT_ACTIVE_COMPONENT="CURRENT_ACTIVE_COMPONENT",
    SET_DEFAULT_MOVING_ROOM="SET_DEFAULT_MOVING_ROOM",
    CURRENT_DEFAULT_ACTIVE_ROOMTYPE="CURRENT_DEFAULT_ACTIVE_ROOMTYPE",
    ACTIVE_ROOF_VIEW="ACTIVE_ROOF_VIEW",
    DEFAULT_ROOF_VIEW="DEFAULT_ROOF_VIEW",
    DEFAULT_ACTIVE_COMPONENT="DEFAULT_ACTIVE_COMPONENT",
    CURRENT_MODE_CHANGE="CURRENT_MODE_CHANGE",
    // SET_DEFAULT_ROOF_VIEW="SET_DEFAULT_ROOF_VIEW",
    THEME_MODE_ACTIVE_COLOR="THEME_MODE_ACTIVE_COLOR",
    DEFAULT_CAMERA_INDEX_FROM_BACKEND='DEFAULT_CAMERA_INDEX_FROM_BACKEND',
    AVAILABLE_COMPONENT="AVAILABLE_COMPONENT",

    //SLIDER SUBCATEGORY
    DEFAULT_SUBCATEGORY='DEFAULT_SUBCATEGORY',
    SET_ROOMCATEGORY="SET_ROOMCATEGORY",
    SET_ACTIVE_DEFAULT_SUBCATEGORY="SET_ACTIVE_DEFAULT_SUBCATEGORY",
    NUMBER_OF_CAMERAS_AVAILABLE='NUMBER_OF_CAMERAS_AVAILABLE',

    // MOBILE ACTION_TYPE
    MOBILE_DROPDOWN_OPEN = "MOBILE_DROPDOWN_OPEN",
    ROOF_VIEW_TOGGLE = "ROOF_VIEW_TOGGLE",

    // COMPONENT DESCRIPTION MODAL
    UPDATE_OPTION_LIST_DETAIL = "UPDATE_OPTION_LIST_DETAIL",

    //BUILDING DATA
    LOAD_BUILDING_DATA = 'LOAD_BUILDING_DATA',

    // AUTO MODE ACTION TYPE
    AUTO_MODE_TIMER="AUTO_MODE_TIMER",

    // MAIN SCREEN ACTION TYPE
    MAIN_SCREEN_SUCCESS = "MAIN_SCREEN_SUCCESS",
    UPDATE_TIME = "UPDATE_TIME",
}