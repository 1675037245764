import MobileCameraRotationView from "./MobileCameraRotationView";
import RoofViewHandler from "../../rightsection/RoofViewHandler";
import AnimationGifs from "../../Common/AnimationGifs";
import { useEffect, useState } from "react";

const MobileRoofView = ({handleRoofViewCallBack}:any) => {
  const {
    isOpen,
    activeRoofView,
    dollIsOpen,
    handleDollHouseView,
    setDollIsOpen,
    setIsOpen,
    handleRoofView,
    handleRotateCamera,
  } = RoofViewHandler();

  const [showGif, setShowGif] = useState(false);

  const showGifs = () => {
    setShowGif(true);
    setTimeout(() => {
      setShowGif(false);
    }, 3000);
  };

  useEffect(() => {
    if (isOpen) {
      showGifs();
    }
  }, [isOpen]);

  return (
    <>
      {showGif && (
        <AnimationGifs />
      )}
      <div className="leftSide_mode">
        <DollView
          dollIsOpen={dollIsOpen}
          handleDollHouseView={handleDollHouseView}
          setDollIsOpen={setDollIsOpen}
        />
        <RoofView
          isOpen={isOpen}
          activeRoofView={activeRoofView}
          handleRoofView={handleRoofView}
          setIsOpen={setIsOpen}
          handleRoofViewCallBack={handleRoofViewCallBack}
        />
        <MobileCameraRotationView
          activeRoofView={activeRoofView}
          handleRotateCamera={handleRotateCamera}
        />
      </div>
    </>
  );
};

interface rooftype {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleRoofView: () => void;
  activeRoofView: string;
  handleRoofViewCallBack:any
}

interface proptype {
  dollIsOpen: boolean;
  handleDollHouseView: () => void;
  setDollIsOpen: (isOpen: boolean) => void;
}

const DollView = (props: proptype) => {
  const { handleDollHouseView, setDollIsOpen, dollIsOpen } = props;
  return (
    <div
      onTouchStart={() => {
        handleDollHouseView();
        setDollIsOpen(!dollIsOpen);
      }}
      className={`auto_mode step step-11 ${dollIsOpen && "activeMode_auto"}`}
    >
      <img
        src={`/images/svgIcons/${!dollIsOpen ? "dor_view" : "DollviewBlack"
          }.svg`}
        alt="dorView"
      />
    </div>
  );
};

const RoofView = (props: rooftype) => {
  const { handleRoofView, setIsOpen, activeRoofView, isOpen, handleRoofViewCallBack } = props;
  return (
    <>
      <div
        onTouchStart={() => {
          handleRoofView();
          setIsOpen(!isOpen);
          handleRoofViewCallBack(!isOpen);
        }}
        className={`auto_mode step step-10 ${isOpen && "activeMode_auto"}`}
      >
        <img
          src={`/images/svgIcons/${activeRoofView !== "Active" ? "roofView" : "BlackRoof"
            }.svg`}
          alt="roofView"
        />
      </div>
    </>
  );
};

export default MobileRoofView;
