import  ReactDOM from "react-dom";
import styled from "styled-components";


const MainImageModalDiv = styled.div`
    position: absolute;
    text-align: center;
    padding: 0px 30px;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    font-size: 14px;
    color: #ffffff;
    margin: auto;
    // height: 150px;
    z-index: 9999999;
    background:rgb(0,0,0,0.8);
    width:100%;
    height:100vh;

    .rotate_img{
    position:relative;
    width:100%;
    height:100%;
    }
    img{
    width: 100%;
    top: 50%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%,50%);
    -ms-transform: translate(-50%,50%);
    transform: translate(-50%,-50%);
    }
`;
interface proptype{
    children:boolean | {},
    isPortrait:{}
}

const ShowModal = ({children, isPortrait}:proptype) => {

    if(!isPortrait) return null;
    const rootElement: HTMLElement = document.getElementById("ImageModal")!;

    return ReactDOM.createPortal(
        <MainImageModalDiv>
            <div className="rotate_img">
                {children}
            </div>
        </MainImageModalDiv>,
       rootElement
    )
}


export default ShowModal;