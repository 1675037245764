import { ActionType } from "../action-types";

interface typeAtion{
    type: string,
     payload: number}

export const INITIAL_STATE = {
    viewAngle : []
}

export default (state: object = INITIAL_STATE, action: typeAtion): object => {
    switch (action.type) {
        case ActionType.COMPASS:
            return { ...state, viewAngle: action.payload }
        default:
            return state;
    }
};