import { Fragment } from "react";
import { RoomType2, navType } from "../../../Types";




const NavbarTypeOfRoomView = (props: navType) => {

    const {
        items,
        isToggleOpen,
        handleRoomActive,
        currentActiveRoomType,
    } = props;

    return (
        <Fragment>
            {isToggleOpen[1] && <div className="type_buliding">
                <div className="type_bulidingItem">
                    <ul>
                        <li className="buliding_active1">Type of Rooms</li>
                    </ul>
                </div>
                <div className="flat_numbering">
                    <ul>
                        {items.currentItems.Room?.RoomTypes.map((roomType: RoomType2, index: number) => {
                            const lengthOfRoomType = items.currentItems.Room?.RoomTypes?.length
                            return (
                                <li
                                    data-component={roomType.RoomType.Components.length}
                                    data-name={roomType.RoomType.Name}
                                    data-value={index}
                                    onClick={(e: React.MouseEvent<HTMLLIElement>) => handleRoomActive(e)}
                                    className={`${lengthOfRoomType === 1 ? '' : 'singleItem'} ${currentActiveRoomType === roomType.RoomType.Name && 'room_active'}`}
                                    key={index}
                                >
                                    <img src="/images/svgIcons/1BHK_ic.svg" className={`image_bluding`} alt="img" />
                                    {roomType.RoomType.Name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>}
        </Fragment>
    );
}


export default NavbarTypeOfRoomView;