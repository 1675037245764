

function DollViewSvgIcons({ width, height, fill, viewBox, currentMode }:any) {
    return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
        <line y1="0.5" x2="28" y2="0.5" stroke={fill}/>
        <line x1="5" y1="5.5" x2="23" y2="5.5" stroke={fill}/>
        <line x1="10" y1="10.5" x2="28" y2="10.5" stroke={fill}/>
        <line x1="10" y1="18.5" x2="19" y2="18.5" stroke={fill}/>
        <line y1="27.5" x2="11" y2="27.5" stroke={fill}/>
        <line x1="0.5" x2="0.5" y2="28" stroke={fill}/>
        <line x1="5.5" y1="5" x2="5.5" y2="23" stroke={fill}/>
        <line x1="10.5" y1="10" x2="10.5" y2="27" stroke={fill}/>
        <line x1="18.5" y1="5" x2="18.5" y2="6" stroke={fill}/>
        <line x1="27.5" x2="27.5" y2="11" stroke={fill}/>
        <line x1="0.353553" y1="0.646447" x2="10.3536" y2="10.6464" stroke={fill}/>
        <line x1="5.35355" y1="22.6464" x2="10.3536" y2="27.6464" stroke={fill}/>
        <line x1="22.3536" y1="5.64645" x2="27.3536" y2="10.6464" stroke={fill}/>
        <line x1="5.35355" y1="23.3536" x2="1.35355" y2="27.3536" stroke={fill}/>
        <line x1="27.3123" y1="1.39043" x2="22.3123" y2="5.39043" stroke={fill}/>
        <line x1="18.5" y1="10" x2="18.5" y2="18" stroke={fill}/>
    </svg>
    );
  }
  
  export default DollViewSvgIcons;