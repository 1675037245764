import { navbarType } from "../../../Types";
import NavbarItem from "./NavbarItem";



const NavbarRoomView = (props: navbarType) => {
    return (
        <NavbarItem 
            isToggleOpen={props.isToggleOpen[1]} 
            handleUpdateToggleState={props.handleUpdateToggleState} 
            index={1} 
            activeClass='buliding_active' 
            activeSrc='RoomTypeBlack' 
            inactiveSrc='RoomType'
            step="step step-7"
        />
    );
    }


    export default NavbarRoomView;