

function ToggleArrowSvgIcons({ width, height, fill, viewBox, rotation }:any) {
    return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
        <path d={rotation} stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    );
  }
  
  export default ToggleArrowSvgIcons;