import { Fragment } from "react";
import { OptionList } from "../Types";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { isMobile } from "react-device-detect";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { activeComponentItem } from "../../services/navbarServices";
import { componentHoveredMode } from "../../services/componentServices";
import { componentChangeableMode } from "../../services/componentServices";
import ComponentDescriptionItemsHandler from "../ComponentDescriptionItemsHandler";

export const SubComponentItems = () => {
  const { componentItem } = ComponentDescriptionItemsHandler();

  const {
    selectedComponentRoom,
    currentActiveComponent: activeComponentInput,
  } = useTypedSelector(({ component }) => component);

  const handleSubComponent = (currentMode: string) => {
    componentChangeableMode(selectedComponentRoom, currentMode);
    activeComponentItem({
      Option: currentMode,
    });
  };

  return (
    <ul>
      {componentItem?.map((component: OptionList, index: number) => {
        let currentItem = component?.OptionItems?.item;
        if (currentItem !== undefined) {
          return (
            <Fragment key={index}>
              <li
                className={`${
                  currentItem === activeComponentInput && "selected_active2"
                }`}
                onClick={() => handleSubComponent(currentItem)}
                onMouseEnter={() =>
                  componentHoveredMode(
                    selectedComponentRoom,
                    "Option_Hovered",
                    currentItem
                  )
                }
                onMouseLeave={() =>
                  componentHoveredMode(
                    selectedComponentRoom,
                    "Option_UnHovered",
                    currentItem
                  )
                }
              >
                <img
                  data-tooltip-content={currentItem}
                  data-tooltip-id={currentItem}
                  src={`/images/KitchenChairShades/${currentItem}.png`}
                  alt="variations"
                />
                {!isMobile && (
                  <Tooltip
                    id={currentItem}
                    place="top"
                    // effect={{ type: 'solid' }}
                  />
                )}
              </li>
            </Fragment>
          );
        }
      })}
    </ul>
  );
};
