import "./SnapshotTaker.css";
import { useState } from "react";
import MySvgIcon from "./SvgIcons";
import UseToggle from "../UseToggle";
import { captureWindowScreenshot } from "../../services/navbarServices";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";

interface screenType{
    type: string, 
    resX: number, 
    resY: number 
}

const SnapshotTaker = ({isFullScreen, helpButtonToggle}:any) => {

  const [isHover, setIsHover] = useState(false)

  const {
    isToggleOpen,
    targetCurrentElementRef,
    handleUpdateToggleState
  } = UseToggle([false]);
 
    const snapShotTypes = [
        {
          type: "HD",
          resX: 1920,
          resY: 1080
        },
        {
          type: "2k",
          resX: 2560,
          resY: 1440
        },
        {
          type: "4k",
          resX: 3840,
          resY: 2160
        }
      ];

    const takeScreenShot = (snapShotType:screenType) => {
      captureWindowScreenshot(snapShotType.resX, snapShotType.resY);
      handleUpdateToggleState(0)
    }

    return (
        <div ref={targetCurrentElementRef} className={`list_container ${isFullScreen && "onExpandContentMoving"}`}>
            <ul className="all_list">
              {isToggleOpen[0] && snapShotTypes.map((item:screenType, index:number) => {
                  return <li
                  key={index} 
                  onClick={() => takeScreenShot(item)}
                  className="activeResolution"
                  >{item["resY"]}<span>{item.type}</span>
                  </li>
                })
              }
            </ul>
           <div 
              onClick={() => handleUpdateToggleState(0)} 
              onMouseOver={() => setIsHover(true)} 
              onMouseLeave={() => setIsHover(false)}
              className={`ImageMode step step-7 ${isToggleOpen[0] && 'active'}`}
            >
              <MySvgIcon 
                width="29" 
                height="29" 
                viewBox="0 0 29 29" 
                fill={isHover || isToggleOpen[0] ? 'black' : 'white'}
              />
            </div> 
            {isHover && helpButtonToggle && 
              <HelpSection 
                text="Capture property snapshot" 
                iconSpace="" 
                className="snapshot_help_toolTip" 
                icon={<HelpRightIcon />}
                />
            }
        </div>
    );
}


export default SnapshotTaker;