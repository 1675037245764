


function BedSheetSvgIcons({ width, height, fill, viewBox, currentMode }:any) {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_271_159071)">
<path d="M0.4375 19.375C0.195563 19.375 0 19.1794 0 18.9375C0 17.2492 1.37419 15.875 3.0625 15.875C3.30444 15.875 3.5 16.071 3.5 16.3125C3.5 16.554 3.30444 16.75 3.0625 16.75C1.85631 16.75 0.875 17.7313 0.875 18.9375C0.875 19.1794 0.679437 19.375 0.4375 19.375Z" fill={fill}/>
<path d="M27.5625 19.375C27.321 19.375 27.125 19.1794 27.125 18.9375C27.1246 17.7313 26.1433 16.75 24.9375 16.75C24.6956 16.75 24.5 16.554 24.5 16.3125C24.5 16.071 24.6956 15.875 24.9375 15.875C26.6254 15.875 27.9996 17.2488 28 18.9375C28 19.179 27.8044 19.375 27.5625 19.375Z" fill={fill}/>
<path d="M24.9375 16.751H3.0625C2.82056 16.751 2.625 16.555 2.625 16.3135C2.625 16.072 2.82056 15.876 3.0625 15.876H24.9375C25.1794 15.876 25.375 16.072 25.375 16.3135C25.375 16.555 25.1794 16.751 24.9375 16.751Z" fill={fill}/>
<path d="M0.4375 23.7526C0.195563 23.7526 0 23.5571 0 23.3151V18.9375C0 18.6956 0.195563 18.5 0.4375 18.5C0.679437 18.5 0.875 18.6956 0.875 18.9375V23.3151C0.875 23.5571 0.679437 23.7526 0.4375 23.7526Z" fill={fill}/>
<path d="M1.3125 24.627C0.588875 24.627 0 24.0381 0 23.3145C0 23.0725 0.195563 22.877 0.4375 22.877C0.679437 22.877 0.875 23.0725 0.875 23.3145C0.875 23.556 1.071 23.752 1.3125 23.752C1.55444 23.752 1.75 23.9475 1.75 24.1895C1.75 24.4314 1.55444 24.627 1.3125 24.627Z" fill={fill}/>
<path d="M27.5625 23.7526C27.3206 23.7526 27.125 23.5571 27.125 23.3151V18.9375C27.125 18.6956 27.3206 18.5 27.5625 18.5C27.8044 18.5 28 18.6956 28 18.9375V23.3151C28 23.5571 27.8044 23.7526 27.5625 23.7526Z" fill={fill}/>
<path d="M26.6875 24.627C26.4456 24.627 26.25 24.4314 26.25 24.1895C26.25 23.9475 26.4456 23.752 26.6875 23.752C26.929 23.752 27.125 23.556 27.125 23.3145C27.125 23.0725 27.3206 22.877 27.5625 22.877C27.8044 22.877 28 23.0725 28 23.3145C28 24.0381 27.4111 24.627 26.6875 24.627Z" fill={fill}/>
<path d="M26.6875 24.627H1.3125C1.07056 24.627 0.875 24.4314 0.875 24.1895C0.875 23.9475 1.07056 23.752 1.3125 23.752H26.6875C26.9294 23.752 27.125 23.9475 27.125 24.1895C27.125 24.4314 26.9294 24.627 26.6875 24.627Z" fill={fill}/>
<path d="M27.5625 22.4385H0.4375C0.195563 22.4385 0 22.2429 0 22.001C0 21.759 0.195563 21.5635 0.4375 21.5635H27.5625C27.8044 21.5635 28 21.759 28 22.001C28 22.2429 27.8044 22.4385 27.5625 22.4385Z" fill={fill}/>
<path d="M2.18799 26.3761C1.94605 26.3761 1.75049 26.1805 1.75049 25.9386V24.1895C1.75049 23.9475 1.94605 23.752 2.18799 23.752C2.42993 23.752 2.62549 23.9475 2.62549 24.1895V25.9386C2.62549 26.1805 2.42993 26.3761 2.18799 26.3761Z" fill={fill}/>
<path d="M6.56299 26.376H2.18799C1.94605 26.376 1.75049 26.1804 1.75049 25.9385C1.75049 25.6965 1.94605 25.501 2.18799 25.501H6.56299C6.80493 25.501 7.00049 25.6965 7.00049 25.9385C7.00049 26.1804 6.80493 26.376 6.56299 26.376Z" fill={fill}/>
<path d="M6.56299 26.3761C6.32105 26.3761 6.12549 26.1805 6.12549 25.9386V24.1895C6.12549 23.9475 6.32105 23.752 6.56299 23.752C6.80493 23.752 7.00049 23.9475 7.00049 24.1895V25.9386C7.00049 26.1805 6.80493 26.3761 6.56299 26.3761Z" fill={fill}/>
<path d="M21.437 26.3761C21.1951 26.3761 20.9995 26.1805 20.9995 25.9386V24.1895C20.9995 23.9475 21.1951 23.752 21.437 23.752C21.6789 23.752 21.8745 23.9475 21.8745 24.1895V25.9386C21.8745 26.1805 21.6789 26.3761 21.437 26.3761Z" fill={fill}/>
<path d="M25.812 26.376H21.437C21.1951 26.376 20.9995 26.1804 20.9995 25.9385C20.9995 25.6965 21.1951 25.501 21.437 25.501H25.812C26.0539 25.501 26.2495 25.6965 26.2495 25.9385C26.2495 26.1804 26.0539 26.376 25.812 26.376Z" fill={fill}/>
<path d="M25.812 26.3761C25.5701 26.3761 25.3745 26.1805 25.3745 25.9386V24.1895C25.3745 23.9475 25.5701 23.752 25.812 23.752C26.0539 23.752 26.2495 23.9475 26.2495 24.1895V25.9386C26.2495 26.1805 26.0539 26.3761 25.812 26.3761Z" fill={fill}/>
<path d="M3.0625 16.7505C2.82056 16.7505 2.625 16.5545 2.625 16.313V9.31348C2.625 9.07198 2.82056 8.87598 3.0625 8.87598C3.30444 8.87598 3.5 9.07198 3.5 9.31348V16.3126C3.5 16.5545 3.30444 16.7505 3.0625 16.7505Z" fill={fill}/>
<path d="M24.9375 16.7505C24.6956 16.7505 24.5 16.5545 24.5 16.313V9.31348C24.5 9.07198 24.6956 8.87598 24.9375 8.87598C25.1794 8.87598 25.375 9.07198 25.375 9.31348V16.3126C25.375 16.5545 25.1794 16.7505 24.9375 16.7505Z" fill={fill}/>
<path d="M24.0617 8.87598H3.93848C3.69654 8.87598 3.50098 8.67998 3.50098 8.43848C3.50098 8.19698 3.69654 8.00098 3.93848 8.00098H24.0617C24.3037 8.00098 24.4992 8.19698 24.4992 8.43848C24.4992 8.67998 24.3037 8.87598 24.0617 8.87598Z" fill={fill}/>
<path d="M5.68652 15.0014C5.44459 15.0014 5.24902 14.8054 5.24902 14.5639C5.24902 13.8399 5.8379 13.251 6.56152 13.251C6.80346 13.251 6.99902 13.447 6.99902 13.6885C6.99902 13.93 6.80346 14.126 6.56152 14.126C6.32002 14.126 6.12402 14.3224 6.12402 14.5639C6.12402 14.8059 5.92846 15.0014 5.68652 15.0014Z" fill={fill}/>
<path d="M12.688 15.0005C12.4461 15.0005 12.2505 14.8045 12.2505 14.563C12.2505 14.322 12.0545 14.126 11.813 14.126C11.5711 14.126 11.3755 13.93 11.3755 13.6885C11.3755 13.447 11.5711 13.251 11.813 13.251C12.5366 13.251 13.1255 13.8394 13.1255 14.563C13.1255 14.805 12.9299 15.0005 12.688 15.0005Z" fill={fill}/>
<path d="M5.68701 16.752C5.44507 16.752 5.24951 16.556 5.24951 16.3145V14.5645C5.24951 14.323 5.44507 14.127 5.68701 14.127C5.92895 14.127 6.12451 14.323 6.12451 14.5645V16.3145C6.12451 16.5564 5.92895 16.752 5.68701 16.752Z" fill={fill}/>
<path d="M12.688 16.7497C12.4461 16.7497 12.2505 16.5537 12.2505 16.3122V14.5635C12.2505 14.322 12.4461 14.126 12.688 14.126C12.9299 14.126 13.1255 14.322 13.1255 14.5635V16.3122C13.1255 16.5537 12.9299 16.7497 12.688 16.7497Z" fill={fill}/>
<path d="M11.8138 14.126H6.56201C6.32007 14.126 6.12451 13.93 6.12451 13.6885C6.12451 13.447 6.32007 13.251 6.56201 13.251H11.8138C12.0557 13.251 12.2513 13.447 12.2513 13.6885C12.2513 13.93 12.0557 14.126 11.8138 14.126Z" fill={fill}/>
<path d="M15.3125 15.0014C15.0706 15.0014 14.875 14.8054 14.875 14.5639C14.875 13.8403 15.4639 13.251 16.1875 13.251C16.4294 13.251 16.625 13.447 16.625 13.6885C16.625 13.93 16.4294 14.126 16.1875 14.126C15.946 14.126 15.75 14.3224 15.75 14.5639C15.75 14.8059 15.5544 15.0014 15.3125 15.0014Z" fill={fill}/>
<path d="M22.3142 15.0005C22.0723 15.0005 21.8767 14.8045 21.8767 14.563C21.8767 14.322 21.6807 14.126 21.4392 14.126C21.1973 14.126 21.0017 13.93 21.0017 13.6885C21.0017 13.447 21.1973 13.251 21.4392 13.251C22.1628 13.251 22.7517 13.8394 22.7517 14.563C22.7517 14.805 22.5561 15.0005 22.3142 15.0005Z" fill={fill}/>
<path d="M15.3125 16.752C15.0706 16.752 14.875 16.556 14.875 16.3145V14.5645C14.875 14.323 15.0706 14.127 15.3125 14.127C15.5544 14.127 15.75 14.323 15.75 14.5645V16.3145C15.75 16.5564 15.5544 16.752 15.3125 16.752Z" fill={fill}/>
<path d="M22.3142 16.7497C22.0723 16.7497 21.8767 16.5537 21.8767 16.3122V14.5635C21.8767 14.322 22.0723 14.126 22.3142 14.126C22.5561 14.126 22.7517 14.322 22.7517 14.5635V16.3122C22.7517 16.5537 22.5561 16.7497 22.3142 16.7497Z" fill={fill}/>
<path d="M21.4393 14.126H16.1875C15.9456 14.126 15.75 13.93 15.75 13.6885C15.75 13.447 15.9456 13.251 16.1875 13.251H21.4393C21.6812 13.251 21.8768 13.447 21.8768 13.6885C21.8768 13.93 21.6812 14.126 21.4393 14.126Z" fill={fill}/>
<path d="M3.0625 9.75087C2.82056 9.75087 2.625 9.55487 2.625 9.31337C2.625 8.58931 3.21431 8 3.93837 8C4.18031 8 4.37587 8.196 4.37587 8.4375C4.37587 8.679 4.18031 8.875 3.93837 8.875C3.69644 8.875 3.5 9.07187 3.5 9.31337C3.5 9.55487 3.30444 9.75087 3.0625 9.75087Z" fill={fill}/>
<path d="M24.9374 9.75087C24.6955 9.75087 24.4999 9.55487 24.4999 9.31337C24.4999 9.07187 24.3035 8.875 24.0615 8.875C23.8196 8.875 23.624 8.679 23.624 8.4375C23.624 8.196 23.8196 8 24.0615 8C24.7856 8.00044 25.3749 8.58931 25.3749 9.31337C25.3749 9.55531 25.1793 9.75087 24.9374 9.75087Z" fill={fill}/>
</g>
<defs>
<clipPath id="clip0_271_159071">
<rect width={width} height={height} fill={fill}/>
</clipPath>
</defs>
</svg>
    );
    // return (
    // <svg width={width} height={height} viewBox={viewBox} fill="none">
    //     <path d="M27.1575 19.6139H2.84353C2.35978 19.6139 1.89583 19.4217 1.55377 19.0797C1.2117 18.7376 1.01953 18.2737 1.01953 17.7899V15.3589C1.01953 14.8752 1.2117 14.4112 1.55377 14.0691C1.89583 13.7271 2.35978 13.5349 2.84353 13.5349H27.1575C27.6413 13.5349 28.1052 13.7271 28.4473 14.0691C28.7894 14.4112 28.9815 14.8752 28.9815 15.3589V17.7899C28.9815 18.2737 28.7894 18.7376 28.4473 19.0797C28.1052 19.4217 27.6413 19.6139 27.1575 19.6139V19.6139Z" stroke={fill} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //     <path d="M2.23535 13.6379V3.20193C2.23535 2.71817 2.42752 2.25423 2.76959 1.91217C3.11166 1.5701 3.5756 1.37793 4.05935 1.37793H25.9414C26.4251 1.37793 26.889 1.5701 27.2311 1.91217C27.5732 2.25423 27.7654 2.71817 27.7654 3.20193V13.6399M3.45135 19.6139V22.0449H4.66635L5.88235 19.6139M26.5494 19.6139V22.0449H25.3354L24.1194 19.6139" stroke={fill} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    //     <path d="M4.6665 12.3199C4.88437 11.9655 5.18539 11.6694 5.54348 11.4576C5.90157 11.2457 6.30592 11.1243 6.7215 11.1039H11.1215C11.5371 11.1243 11.9414 11.2457 12.2995 11.4576C12.6576 11.6694 12.9586 11.9655 13.1765 12.3199C12.9586 12.6744 12.6576 12.9704 12.2995 13.1823C11.9414 13.3942 11.5371 13.5156 11.1215 13.5359H6.7215C6.30592 13.5156 5.90157 13.3942 5.54348 13.1823C5.18539 12.9704 4.88437 12.6744 4.6665 12.3199V12.3199ZM16.8235 12.3199C17.0414 11.9655 17.3424 11.6694 17.7005 11.4576C18.0586 11.2457 18.4629 11.1243 18.8785 11.1039H23.2785C23.6941 11.1243 24.0984 11.2457 24.4565 11.4576C24.8146 11.6694 25.1156 11.9655 25.3335 12.3199C25.1156 12.6744 24.8146 12.9704 24.4565 13.1823C24.0984 13.3942 23.6941 13.5156 23.2785 13.5359H18.8785C18.4629 13.5156 18.0586 13.3942 17.7005 13.1823C17.3424 12.9704 17.0414 12.6744 16.8235 12.3199V12.3199Z" stroke={fill} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    // );
  }
  
  export default BedSheetSvgIcons;