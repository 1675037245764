export const webSteps = [
    {
      selector: '.step-2',
      content: 'Click to open a list of building types. Choose to transform the viewed model.',
    },
    {
      selector: '.step-3',
      content: 'Click to see room types. Select one to change the current room.',
    },
    {
      selector: '.step-4',
      content: 'Select room elements to modify style, color, or remove.',
    },
    {
      selector: '.step-5',
      content: 'Select room elements to modify style, color, or remove.',
    },
    {
      selector: '.step-6',
      content: 'Currently unavailable. When enabled, toggle between automatic tour and manual exploration.',
    },
    {
      selector: '.step-7',
      content: 'Click to take an image of the current view. Adjust quality using the slider.',
    },
    {
      selector: '.step-8',
      content: 'Change your camera position within the current room for a full view.',
    },
    {
      selector: '.step-9',
      content: 'Shows all rooms. Click a room name to teleport there.',
    },
    {
      selector: '.step-10',
      content: 'Click to switch to a top-down view from the roof.',
    },
    {
      selector: '.step-11',
      content: 'Currently disabled. When enabled, see a dollhouse-like 3D view.',
    },
    {
      selector: '.step-12',
      content: "Click to enlarge the view to full-screen. Click again or press 'Esc' to exit.",
    },
    {
      selector: '.step-13',
      content: 'Modify virtual time to experience different lighting conditions.',
    },
    {
      selector: '.step-14',
      content: 'Displays the property is orientation in relation to cardinal points.',
    },
    {
      selector: ".step-15",
      content: "I Hope! It must have helped you."
    }
];



export const mobileSteps = [
    {
      selector: '.step-1',
      content: 'Select room elements to modify style, color, or remove.',
    },
    {
      selector: '.step-2',
      content: 'Select room elements to modify style, color, or remove.',
    },
    {
      selector: '.step-3',
      content: 'Change your camera position within the current room for a full view.',
    },
    {
      selector: '.step-4',
      content: 'Shows all rooms. Click a room name to teleport there.',
    },
    {
      selector: '.step-5',
      content: 'Displays the property is orientation in relation to cardinal points.',
    },
    {
      selector: '.step-6',
      content: 'Click to open a list of building types. Choose to transform the viewed model.',
    },
    {
      selector: '.step-7',
      content: 'Click to see room types. Select one to change the current room.',
    },
    {
      selector: '.step-8',
      content: 'Modify virtual time to experience different lighting conditions.',
    },
    
    // {
    //   selector: '.step-6',
    //   content: '"Guided Tour Switch (currently unavailable)"',
    // },
    {
      selector: '.step-9',
      content: 'Currently unavailable. When enabled, toggle between automatic tour and manual exploration.',
    },
    
    
    {
      selector: '.step-10',
      content: 'Click to switch to a top-down view from the roof.',
    },
    {
      selector: '.step-11',
      content: 'Currently disabled. When enabled, see a dollhouse-like 3D view.',
    },
    {
      selector: ".step-12",
      content: "I Hope ! It must have helped you."
    }
    // {
    //   selector: '.step-12',
    //   content: 'updating....',
    // },
    
    
];