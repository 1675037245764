import { useEffect, useState } from "react";
import { useTypedSelector } from "../hooks/use-typed-selector";
import { useActions } from "../hooks/use-actions";
import { activeComponentItem } from "../services/navbarServices";



const ComponentDescriptionItemsHandler = () => {
  const [componentItem, setComponentItem] = useState<any>([]);
  const { RoomTypes } = useTypedSelector(({ navbar }) => navbar.buildingnavData.BuildingType);
  const { selectRoom, defaultSubcategory } = useTypedSelector(({ footernavbar }) => footernavbar);
  const { selectedComponentRoom, currentActiveComponent: activeComponentInput } = useTypedSelector(({ component }) => component);
  const { selectedRoomType } = useTypedSelector(({ navbar }) => navbar);
  const { defaultComponentRoom } = useTypedSelector(({ allscreens }) => allscreens)

  const { CurrentDefaultActiveRoomType, updateOptionListDetail } = useActions();

  useEffect(() => {
    RoomTypes.forEach((items: any) => {
      if (selectRoom) {
        if (items.Room.Name === selectRoom) {
          CurrentDefaultActiveRoomType(items.Room.DefaultRoomType)
          items.Room.RoomTypes.forEach((roomType: any) => {
            if (items.Room.DefaultRoomType === roomType.RoomType.Name) {
              roomType.RoomType.Components.forEach((component: any) => {
                if (component.Component === selectedComponentRoom) {
                  setComponentItem(component?.OptionList)
                }
              })
            }
          })
        }
      }
    })
  }, [selectRoom, selectedComponentRoom, selectedRoomType, defaultSubcategory]);

  useEffect(() => {
    componentItem?.map((component: any) => {
      if (component?.OptionItems?.item !== undefined) {
        let currentItem = component?.OptionItems
        if (activeComponentInput === component?.OptionItems?.item) {
          if (activeComponentInput !== "") {
            updateOptionListDetail(
              {
                componentList: currentItem
              }
            );
          }
        }
      }
    })
  }, [activeComponentInput]);


  useEffect(() => {
    RoomTypes.forEach((items: any) => {
      if (selectRoom) {
        if (items.Room.Name === selectRoom) {
          items.Room.RoomTypes.forEach((roomType: any) => {
            if (items.Room.DefaultRoomType === roomType.RoomType.Name) {
              roomType.RoomType.Components.forEach((component: any) => {
                if (component.Component === selectedComponentRoom) {
                  component?.OptionList.forEach((list: any) => {
                    if (component.Component === defaultComponentRoom) {
                      if (component.CurrentOption === list.OptionItems.item) {
                        activeComponentItem({
                          Option: list.OptionItems.item
                        })
                        updateOptionListDetail(
                          {
                            componentList: list.OptionItems
                          }
                        );
                      }
                    }
                  })
                }
              });
            }
          })
        }
      }
    })
  }, [defaultComponentRoom]);

  return { componentItem };
}

export default ComponentDescriptionItemsHandler;