import { MediaController } from "../../MediaController";



const MobileAutoMode = () => {
    return <div className="media_play_pause_controller">
        <MediaController />
    </div>
}



export default MobileAutoMode;