import BuildingData from "../BuildingData.json"
import BuildingData1 from "../BuildingData1.json"

export const BuildingsData = () => {
  const isOldBuilding = true;
  return isOldBuilding ? BuildingData1 :  BuildingData;
}

export const DefaultBuildingData = () => {
    const buildingData = BuildingsData();
    const DefaultBuilding:any = buildingData?.BuildingList[buildingData?.BuildingList.length - 1];
    return DefaultBuilding;
}