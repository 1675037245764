import { Webrtc } from "./webrtc";

export const NextPreviousRequest = (request: string) => {
    const ItemRequest = {
        "C_Type": "WtoU",
        "WtoU_Input": "Automatic",
        "Automatic_Input":
        {
            "Input": request
        }
    }
    console.log(ItemRequest, 'next and previews')
    Webrtc.sendJSON(ItemRequest);
}

export const autoModeRequest = (mode: string) => {
    const modeItem = {
        "C_Type" : "WtoE",
        "WtoE_Input" :  "Mode",
        "Mode_Input" : 
        {
            "Mode": mode && "AutoViewer"
        }         
    }
    // console.log(modeItem, 'Manual To Auto Mode')
    Webrtc.sendJSON(modeItem)
}


export const onChangeRequest = (value:string) => {
    const arrowButton = {
        "C_Type" : "WtoE",
        "WtoE_Input" :  "AutoViewer",
        "AutoViewer_Input" : 
        {
            "Input": value
        }
    }
    console.log('arrowButton', arrowButton)
    Webrtc.sendJSON(arrowButton)  
}