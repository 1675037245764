import { ActionType } from "../action-types";



export const INITIAL_STATE = {
    currentUpdatedItems : {
        componentList:{}
    }
}


export default (state = INITIAL_STATE, action:any ) => {
    switch (action.type) {
        case ActionType.UPDATE_OPTION_LIST_DETAIL:
            return {...state, currentUpdatedItems: action.payload}
        default:
            return state
    }
}