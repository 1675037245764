import HelpSection from "../Common/HelpSection";
import DisplayToggleVisibility from "../DisplayToggleVisibility";
import FullScreenSvgIcons from "../FullScreenSvgIcons";
import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";
import classnames from "classnames";




const FullScreenMode = ({
    setIsHover,
    setIsSvgHover,
    isFullScreen,
    handleClickFullScreen,
    isSvgHover,
    isHover,
    handleShowContent,
    helpButtonToggle,
    isShow,
}: any) => {
    return (
        <div
            className="fullMode_btn step step-12"
            onMouseEnter={() => {
                setIsHover(true);
                // isElementHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
                // isElementHover(false);
            }}
        >
            <button
                onMouseOver={() => setIsSvgHover(true)}
                onMouseLeave={() => setIsSvgHover(false)}
                className={classnames(
                    { active_roofMode_expand: isFullScreen },
                    "hoverButton"
                )}
                onClick={handleClickFullScreen}
            >
                <span className="svgRotation">
                    <FullScreenSvgIcons
                        height="31"
                        width="31"
                        viewBox="0 0 31 31"
                        fill={isFullScreen || isSvgHover ? "#3A3A3A" : "white"}
                    />
                </span>
            </button>
            {isHover && !helpButtonToggle && (
                <DisplayToggleVisibility
                    handleShowContent={handleShowContent}
                    isShow={isShow}
                />
            )}
            {isHover && helpButtonToggle && <HelpSection step="1" text="Expand to full-screen" className="fullMode_toggle" iconSpace="right_helpTooltip" icon={<HelpLeftIcon />} />}
        </div>
    );
}


export default FullScreenMode;