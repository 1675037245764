import { useSelector } from "react-redux";
import HelpSection from "../Common/HelpSection";
import { useState } from "react";
import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";

interface stateType{
    compass:{
        viewAngle:[]
    }
}

export const Compass = ({isFullScreen, helpButtonToggle}: any) => {
    const angle = useSelector((state:stateType) => state.compass.viewAngle
    );
    const [isHover, setIsHover] = useState(false);
    
    return (
        <>
            {isHover && helpButtonToggle && <HelpSection text="Check directional orientation" iconSpace="left_helpTooltip" className="compass_tooltip" icon={<HelpLeftIcon />}/>}
        <div onContextMenu={(e) => e.preventDefault()} className={`tamprechar-clock ${isFullScreen && "onExpandContentMoving"}`}>
            <div onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className="compass step step-14">
                <div className="compass-inner"> 

                    <div className="north">N</div>
                    <div className="east">E</div>
                    <div className="west">W</div>
                    <div className="south">S</div>
                    <div className="main-arrow" style={{ transform: `rotate(${angle && angle}deg)` }}>
                      
                        <div className="arrow-up"></div>
                        <div className="arrow-down"></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}