


const ComponentDescriptionModalToggle = ({handleDescriptionToggle, descriptionModalToggle, selectedComponentRoom}:any) => {

    return (
        <div className="ToggleModal">
            {selectedComponentRoom && (
              <>
                <label className="switchbtn">
                  <input
                    onClick={handleDescriptionToggle}
                    checked={!descriptionModalToggle}
                    type="checkbox"
                  />
                  <span className="sliderbtn round"></span>
                </label>
                <span>Hide/Show Description Modal</span>
              </>
            )}
          </div>
    );
}



export default ComponentDescriptionModalToggle;