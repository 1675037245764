import { ActionType } from "../action-types";
import { Action } from "../actions/footernavbar";
import { BuildingsData } from "../../services/buildingData";

interface RoomData {
    selectRoom: any;
    selectedRoomIndex: number;
    selectedRoomTypeIndex:any;
    defaultRoomType:string;
    setDefaultMovingRoom:{
        Room: any,
        CameraIndex: number,
        Type?:string
    };
    defaultSubcategory:string;
    numberOfCamerasAvailable: [{
        CameraIndex:number
    }];
    availableComponent:number | null

}

export const INITIAL_STATE: RoomData = {
    selectRoom: '',
    selectedRoomIndex:0,
    selectedRoomTypeIndex:0,
    defaultRoomType:'',
    setDefaultMovingRoom: {
        Room: 'None',
        CameraIndex: 0
    },
    defaultSubcategory: '',
    numberOfCamerasAvailable:[{
        CameraIndex:0
    }],
    availableComponent: null,
}

export default (state: RoomData = INITIAL_STATE, action: Action): RoomData => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.ROOM:
            return { ...state, selectRoom: action.payload }
        case 'set_selected_room_index':
            return { ...state, selectedRoomIndex: action.payload }
        case 'set_selected_roomType_index':
            return { ...state, selectedRoomTypeIndex: action.payload }
        case ActionType.DEFAULT_ROOM_TYPE:
            return { ...state, defaultRoomType: action.payload }
        case ActionType.SET_DEFAULT_MOVING_ROOM:
            return { ...state, setDefaultMovingRoom: action.payload }
        case ActionType.DEFAULT_SUBCATEGORY:
            return { ...state, defaultSubcategory: action.payload }
        case ActionType.NUMBER_OF_CAMERAS_AVAILABLE:
            return { ...state, numberOfCamerasAvailable: action.payload }
        case ActionType.AVAILABLE_COMPONENT:
            return { ...state, availableComponent: action.payload }
        default:
            return state;
    }
};