import { Action } from "@remix-run/router";
import { Dispatch } from "redux";
import { ActionType } from '../action-types';

interface CurrentTimeType {
    Hours: number,
	Minutes: number
}

export const ThemeModeAction = (themeModeData: any) => {

    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.THEME_MODE,
            payload: themeModeData
        })
    }  
}

export const updateTime = (updatedTime:CurrentTimeType) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.UPDATE_TIME,
            payload: updatedTime
        })
    }
}