import NavbarHandler from "./NavbarHandler";
import { useState, useEffect } from "react";
import { RoomType, RoomType2, } from "../Types";
import { useActions } from "../../hooks/use-actions";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { DefaultActiveComponent } from "../../services/navbarServices";
import { componentSelection, componentUnSelection } from "../../services/componentServices";
import MobileCameraIndexHandler from "../mobileLandscape/FooterSection/MobileCameraIndexHandler";


const ComponentMenuHandler = () => {

  const { items } = NavbarHandler();
  const { gettingTotalCameras } = MobileCameraIndexHandler();
  const [componentItem, setComponentItem] = useState<RoomType2>();
  const [activeComponent, setActiveComponent] = useState<string>();
  const [activeComponentVal, setActiveComponentVal] = useState<number>();
  
  const { selectedComponentRoom } = useTypedSelector(({ component }) => component);
  const {
    selectRoom,
    availableComponent
  } = useTypedSelector(({ footernavbar }) => footernavbar);
  
  const { buildingnavData:
    { BuildingType:
      { RoomTypes, DefaultRoom }
    } } = useTypedSelector(({ navbar }) => navbar);
    
  const { 
    selectedComponentItem, 
    componentCurrentState, 
    updateAvailableComponent 
  } = useActions();

  const onComponentClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, component: string) => {
    const componentVal = event.currentTarget.getAttribute("data-value")
    if (componentVal && component) {
      setActiveComponentVal(parseInt(componentVal))
      setActiveComponent(component)
      selectedComponentItem(component)
      DefaultActiveComponent({ Component: component })
    }

    if (selectedComponentRoom !== component) {
      componentSelection(component)
      componentCurrentState(true)
    }
    else {
      componentUnSelection(component)
      componentCurrentState(false)
      selectedComponentItem("")
    }

  }

  useEffect(() => {
    RoomTypes.forEach((roomType: RoomType) => {
      if (!selectRoom) {
        const { Name, DefaultRoomType } = roomType.Room;
        if (DefaultRoom === Name) {
          const { RoomTypes } = roomType.Room;
          RoomTypes.forEach((roomsComponent: RoomType2) => {
            const { Name } = roomsComponent.RoomType
            if (Name === DefaultRoomType) {
              setComponentItem(roomsComponent)
            }
          })
        }
      }
    })
  }, [RoomTypes])

  const currentDefaultRoomType = items.currentItems.Room?.DefaultRoomType
  useEffect(() => {
    const selectedRoom = RoomTypes.find((room: any) => room.Room.Name === selectRoom);
    if (selectedRoom) {
      const selectedRoomType = selectedRoom.Room.RoomTypes.find((roomType: any) => roomType.RoomType.Name === currentDefaultRoomType);
      if (selectedRoomType) {
        gettingTotalCameras(selectRoom)
        setComponentItem(selectedRoomType)
        updateAvailableComponent(selectedRoomType.RoomType.Components.length);
      }
    }
  }, [selectRoom, currentDefaultRoomType])

  return { activeComponentVal, activeComponent, componentItem, availableComponent, selectedComponentRoom, onComponentClick }
}

export default ComponentMenuHandler;