import React, { Fragment, useState } from "react";
import ComponentMenuHandler from "./ComponentMenuHandler";
import arrow_left from "../../assets/image/arrow-left.png";
import arrow_right from "../../assets/image/arrow-right.png";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { componentHover } from "../../services/componentServices";
import ItemListAction from "../ComponentStateService/ItemListAction";
import { StateActionTypes } from "../ComponentStateService/ItemTypes";
import AvailableSubComponent from "./AvailableSubComponent";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";

const initialState = {
  expand: false,
  width: "57px",
  arrow: arrow_right,
};

export const SubComponentMenu = ({ helpButtonToggle }: any) => {
  const [isHover, setIsHover] = useState(false);
  const [subComponentHover, setSubComponentHover] = useState(false);
  const { items, dispatch } = ItemListAction(initialState);
  const [componentBarOpen, setComponentBarOpen] = useState(false);
  const [isCurrentComponentHover, setIsCurrentComponentHover] = useState(0);
  const { componentItem, availableComponent, onComponentClick } =
    ComponentMenuHandler();

  const { selectedComponentRoom, currentStateOfComponent } = useTypedSelector(
    ({ component }) => component
  );

  const handleClick = () => {
    dispatch({ type: StateActionTypes.TOGGLE_COMPONENT });
    setComponentBarOpen(!componentBarOpen);
  };

  const onComponentHover = (
    componentName: string,
    index: number,
    event: MouseEvent
  ) => {
    event.stopPropagation();
    if (!selectedComponentRoom && componentName) {
      setTimeout(() => {
        componentHover(componentName, "Hovered");
      }, 500);
    }
    setIsHover(true);
    setIsCurrentComponentHover(index);
  };

  const onComponentUnHover = (
    componentName: string,
    index: number,
    event: MouseEvent
  ) => {
    event.stopPropagation();
    if (!selectedComponentRoom && componentName) {
      componentHover(componentName, "Unhovered");
    }
    setIsHover(false);
    setIsCurrentComponentHover(index);
    setSubComponentHover(false);
  };

  return (
    <>
      {componentItem?.RoomType?.Components?.length ? (
        <div
          onMouseOver={() => setSubComponentHover(true)}
          onMouseLeave={() => setSubComponentHover(false)}
          onContextMenu={(e) => e.preventDefault()}
          style={{ width: `${items.width}` }}
          className={`${
            items.expand ? "left-side-manu" : "left-side-manu2 "
          } step step-5`}
          id="components-child-new"
        >
          {subComponentHover && helpButtonToggle && (
            <HelpSection
              text="Edit room elements"
              iconSpace=""
              className="subComponent_tooltip"
              icon={<HelpRightIcon />}
            />
          )}
          <li
            className={`left-icon-main active1 arrow-manual ${
              availableComponent && availableComponent >= 1
                ? ""
                : "left-icon-border-new"
            }`}
            onClick={handleClick}
          >
            <div className="similer-ic">
              <img src={items.arrow} />
              {/* <svg width="23" height="22" viewBox="0 0 10 17" fill="none"><path d="M8.5 15L2 8.5L8.5 2" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path></svg> */}
            </div>
            {items.expand ? <span>Components</span> : ""}
          </li>

          <AvailableSubComponent
            items={items}
            isHover={isHover}
            componentItem={componentItem}
            onComponentHover={onComponentHover}
            onComponentUnHover={onComponentUnHover}
            onComponentClick={onComponentClick}
            componentBarOpen={componentBarOpen}
            availableComponent={availableComponent}
            selectedComponentRoom={selectedComponentRoom}
            isCurrentComponentHover={isCurrentComponentHover}
            currentStateOfComponent={currentStateOfComponent}
          />
        </div>
      ):(<></>)}
    </>
  );
};
