import UseToggle from "../../UseToggle";
import NavbarHandler from "../../leftsection/NavbarHandler";
import NavbarTypeOfBuildingView from "./MobileType/NavbarTypeOfBuildingView";
import NavbarTypeOfRoomView from "./MobileType/NavbarTypeOfRoomView";
import NavbarTypes from "./MobileType/NavbarTypes";

const MobileNavbar = () => {

    const {
        isToggleOpen,
        targetCurrentElementRef,
        handleUpdateToggleState
    } = UseToggle([false, false]);

    const {
        items,
        active,
        allBuildingData,
        handleSelectRoom,
        handleSelectBuilding,
    } = NavbarHandler();

    return (
        <>
    
        <div ref={targetCurrentElementRef} className="buliding_outer">
            <NavbarTypes
                isToggleOpen={isToggleOpen}
                handleUpdateToggleState={handleUpdateToggleState}
            />
            <NavbarTypeOfBuildingView
                activeBuildingType={active}
                isToggleOpen={isToggleOpen}
                allBuildingData={allBuildingData}
                handleBuildingActive={handleSelectBuilding}
            />
            <NavbarTypeOfRoomView
                items={items}
                isToggleOpen={isToggleOpen}
                handleRoomActive={handleSelectRoom}
                currentActiveRoomType={items.currentActiveRoomType}
            />
        </div>
        </>
    );
}

export default MobileNavbar;