import "slick-carousel/slick/slick.css";
import { useState, Fragment } from "react";
import "slick-carousel/slick/slick-theme.css";
import { useActions } from "../../../hooks/use-actions";
import ActiveMobileSubcategory from "./ActiveMobileSubcategory";
import MobileCameraIndexHandler from "./MobileCameraIndexHandler";
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { selectCurrentRoom } from "../../../services/navbarServices";
import TestMobileFooterNavigation from "./TestMobileFooterNavigation";


const MobileSlider = () => {

  let isLoaded = true;

  const {gettingTotalCameras} = MobileCameraIndexHandler();

  const { 
    buildingnavData:{ 
        BuildingType:{
            RoomCategory 
        }
    }
} = useTypedSelector(({ navbar }) => navbar);

  const {changeCurrentMode} = useTypedSelector(({mode}) => mode);
  const {dropdownOpen} = useTypedSelector(({mobileReducer}) => mobileReducer);
  const { setDefaultMovingRoom, defaultSubcategory, selectRoom } = useTypedSelector(({ footernavbar }) => footernavbar);
  const [sortedSubcategory, setSortedSubcategory] = useState<boolean>();
  const [activeMobileRoomIndex, setActiveMobileRoomIndex] = useState<number>();
  const { DefaultSubcategory, MobileDropdownOpen, HandleRoomAction } = useActions();

  let roomCategoryIndex:number;
  
  const handleMobleRoomCategory = (roomcategory:string, roomIndex:number, roomType:string, allCategory:string) => {
    if(roomType == "Single"){
      selectCurrentRoom(roomcategory)
      DefaultSubcategory('')
    }
    MobileDropdownOpen(true)
    setActiveMobileRoomIndex(roomIndex)
    const sortedItem = allCategory?.includes(defaultSubcategory)
    setSortedSubcategory(sortedItem);
    HandleRoomAction(roomcategory);
    gettingTotalCameras(roomcategory);
  }

  const handleMobileSubcategory = (subcategory:string) => {
    DefaultSubcategory(subcategory)
    selectCurrentRoom(subcategory)
    MobileDropdownOpen(false)
    setSortedSubcategory(false);
  }
  
//   useEffect(() => {
//     if (setDefaultMovingRoom.Room != '') {
//         DefaultSubcategory(setDefaultMovingRoom.Room)
//     }else{
//         DefaultSubcategory(setDefaultMovingRoom.Room);
//     }
// }, [setDefaultMovingRoom])


  const findRoomcategoryIndex = RoomCategory.map((room:any, roomIndex: number) => {
    return room.Category.Items?.map((category:string, index: number) => {
        if (defaultSubcategory == category) {
            roomCategoryIndex = roomIndex
            return {
                category,
                index
            }
        }
    })
})

  return <>
    {changeCurrentMode === "Manual" && <>{setDefaultMovingRoom.Room !== "" && <TestMobileFooterNavigation />}</>}
    <div className="mobileView_landscape">

      <div style={{margin: isLoaded ? '0 auto' : '', width: isLoaded ? '50%' : '100%'}} className={`mobileLanscape_sliderList step step-4`}>
        <ul>
          {RoomCategory.map((roomcategory:any, roomIndex:number) => {
            return <li 
            key={roomIndex} 
            onTouchStart={() => handleMobleRoomCategory(roomcategory.Category.Name, roomIndex, roomcategory.Category.Type, roomcategory.Category.Items)  } 
            className={`${selectRoom === roomcategory.Category.Name && 'MobileActive_list'} ${roomIndex == roomCategoryIndex && 'MobileActive_list'}`}
            >
            {roomcategory.Category.Type != "Single" && dropdownOpen && activeMobileRoomIndex == roomIndex &&
            <div className="sliderarrow_top">
              <img src="/images/svgIcons/MobileArrowUp.svg" alt="img"></img>
            </div>
            }
            {sortedSubcategory !== true && <>
              {roomCategoryIndex === roomIndex && roomcategory.Category.Type != "Single" &&
              <ActiveMobileSubcategory roomCategory={roomcategory.Category.Name} defaultSubcategory={defaultSubcategory}/>
            }
            </>}
            {roomcategory.Category.Type !== "Single" && dropdownOpen && activeMobileRoomIndex === roomIndex && 
            <div className="moblieslider_dropdownOuter">
              <div className="moblieslider_dropdown">
                {roomcategory.Category?.Items.map((subcategory:string, subcategoryIndex:number) => {
                  return <Fragment key={subcategoryIndex}>
                  <p 
                    onTouchStartCapture={() => handleMobileSubcategory(subcategory)} 
                    className={`moblieslider_dropdownItem singleItem_mobile ${dropdownOpen && roomCategoryIndex === roomIndex && defaultSubcategory === subcategory && 'active_subcategory'}`}>{subcategory}
                  </p>
                </Fragment>
                })}
              </div>
            </div> 
            }      
            <span>{roomcategory.Category.Name}</span>
            </li>
          })}
        </ul>
      </div>
    </div>
  </>
}


export default MobileSlider;