

const HelpSection = ({text, className, icon, iconSpace}:any) => {
    return (
        <div className={className}>
            <div className={iconSpace}>{icon}</div> 
            <p>{text}</p>
        </div>
    );
}
 
export default HelpSection;