import { Fragment, useState } from "react";
import CameraRotationSvgIcons from "./CameraRotationSvgIcons";

interface propType {
    handleRotateCamera: (value: number) => void,
    activeRoofView: string
}

const MobileCameraRotationView = (props: propType) => {

    const ROTATIONAL_ANGLE = [90, -90];

    const [isShow, setIsShow] = useState(0);
    const [active, setActive] = useState(false);
    const { handleRotateCamera, activeRoofView } = props;


    const handleCameraRotation = (degree: number) => {
        handleRotateCamera(degree)
        setActive(true)
        setIsShow(degree)
    }

    return (
        <Fragment>
            {activeRoofView === "Active" &&
                <Fragment>
                    {
                        ROTATIONAL_ANGLE.map((degree: number, index: number) => {
                            return (
                                <div
                                    key={index}
                                    onTouchStart={() => handleCameraRotation(degree)}
                                    className={`btn_90Item ${active && isShow === degree && 'active'}`}
                                >
                                    <CameraRotationSvgIcons
                                        width="31"
                                        height="24"
                                        viewBox="0 0 31 24"
                                        fill={active && isShow === degree ? "#3A3A3A" : "white"}
                                    />
                                </div>
                            );
                        })
                    }
                </Fragment>
            }
        </Fragment>
    );
}


export default MobileCameraRotationView;