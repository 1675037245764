
interface ModalContainerType {
    className: {
        divMainSet: string,
        divContainer: string,
        divSetInnerBg: string,
        divRow: string,
    },
    toShowDoubleListOfDescriptionModal:() => void,
    componentList: {
        Price:string,
        Color: string,
        Header: string,
        Description: string
    },
    // CloseModal: React.ReactElement,
    DescriptionHeader: React.ReactElement,
    Description: React.ReactElement,
    Price: React.ReactElement,
    Color: React.ReactElement
}

export const ModalContainer = (props: ModalContainerType) => {    
    
    return (
        <div className={props.className.divMainSet}>
            <div className={props.className.divContainer}>
            <div className={`${props.className.divSetInnerBg} ${props.toShowDoubleListOfDescriptionModal()}`} >
                {/* {props.CloseModal} */}
                {props.DescriptionHeader}
                <>
                    {props.Description}
                    {props.componentList.Color !== null && <>
                    {/* {props.Color} */}
                    {props.Color}
                    </>}
                    {props.componentList.Price !== null && <>{props.Price}
                    {/* <div className="fabric-price">RS. 2009</div> */}
                    </>}
                </>
            </div>
            </div>
        </div>
    );
}
