import { useEffect, useState } from "react";
import playImage from "../assets/image/play.png";
import pauseImage from "../assets/image/pause.png";
import { onChangeRequest } from "../services/media";
import 'react-circular-progressbar/dist/styles.css';
import back_arrow from "../assets/image/back_arrow.png";
import next_arrow from "../assets/image/next_arrow.png";
import { useTypedSelector } from "../hooks/use-typed-selector";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

export const MediaController = () => {

    const { timer } = useTypedSelector(({ mode }) => mode);
    const [barDataValue, setBarDataValue] = useState(0);
    const [playPauseState, setPlayPauseState] = useState(false);

    const barFrame = () => {
        if(barDataValue < 100) {
            for(let i=0; i <= Math.round(timer); i++) {
                setBarDataValue(barDataValue + Math.round(100/timer));
            }
        }
    }

    const automaticScreenChange = () => {
        setBarDataValue(0)
        onChangeRequest("Next")
    }

    useEffect(() => {
        if(!playPauseState){
            const timerId = setInterval(barFrame, 1000);
            return function stopTimer() {
                clearInterval(timerId)
            }
        }
    })

    useEffect(() => {
        if(!playPauseState && timer) {
            const autoMode = setInterval(automaticScreenChange, 1000);
            return function stopTimer() {
                clearInterval(autoMode)
            }
        }
    })

    const handlePlayPause = () => {
        if (!playPauseState) {
            setPlayPauseState(true);
            setBarDataValue(0);
            onChangeRequest("Pause")
        } else {
            setPlayPauseState(false);
        }
    }

    return (
        <div className="play-item">
            <button onClick={() => {onChangeRequest("Back");setBarDataValue(0)}} className="play-back" ><img src={back_arrow} /></button>
            <div className="simple-circle" style={{ width: 50, height: 50 }}>
                <CircularProgressbarWithChildren value={barDataValue /100 * 100}
                    strokeWidth={5}
                    background
                    backgroundPadding={6}
                    styles={buildStyles({
                        pathTransitionDuration: 100 / barDataValue,
                        backgroundColor: "rgba(17, 15, 15, 55%)",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        pathTransition: barDataValue === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                    })}
                >
                    <img style={{ width: 15 }} src={playPauseState ? playImage : pauseImage} onClick={handlePlayPause} alt="pause" />
                </CircularProgressbarWithChildren>
            </div>
            <button onClick={() => {onChangeRequest("Next");setBarDataValue(0)}} className="play-next" ><img src={next_arrow} /></button>
        </div>
    );
}