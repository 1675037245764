import { RoomType2 } from "../../Types";
import {store as reduxStore} from "../../../state/store";
import { ActionType } from "../../../state/action-types";
import { useTypedSelector } from "../../../hooks/use-typed-selector";

const MobileCameraIndexHandler = () => {

    const { buildingnavData: { 
        BuildingType: { 
          RoomTypes 
        } 
      } 
    } = useTypedSelector(({ navbar }) => navbar);

    const { numberOfCamerasAvailable } = useTypedSelector(({footernavbar}) => footernavbar);

    async function gettingTotalCameras (categoryRoom?:string) {
      const selectedRoom = RoomTypes.find((room:any) => room.Room.Name === categoryRoom);
      if(selectedRoom) {
        const currentRoomType = selectedRoom.Room.RoomTypes.find((roomType:RoomType2) => {
          if(selectedRoom.Room.DefaultRoomType === roomType.RoomType.Name) {
            return roomType.RoomType.Cameras
          }
        });
        let totalCameraIndex = []
        if(currentRoomType) {
          for(let i = 1; i < currentRoomType.RoomType.Cameras + 1; i++){
            let newCameraIndex = {
              CameraIndex: i
            }
            totalCameraIndex.push(newCameraIndex)
          }
        }
        reduxStore.dispatch({
          type:ActionType.NUMBER_OF_CAMERAS_AVAILABLE,
          payload:totalCameraIndex
        })
      }
    }

    return {
        numberOfCamerasAvailable,
        gettingTotalCameras
    }
}


export default MobileCameraIndexHandler;