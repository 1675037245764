
function TimeIcons({ width, height, fill, viewBox }:any) {
    return (
<svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.9997 3.21436C7.46518 3.21436 5.99362 3.82393 4.90855 4.90889C3.8236 5.994 3.21402 7.46555 3.21402 9.00003C3.21402 10.5345 3.8236 12.0061 4.90855 13.0912C5.99366 14.1761 7.46522 14.7857 8.9997 14.7857C10.5342 14.7857 12.0058 14.1761 13.0908 13.0912C14.1758 12.0061 14.7854 10.5345 14.7854 9.00003C14.7854 7.46555 14.1758 5.99396 13.0908 4.90889C12.0057 3.82393 10.5342 3.21436 8.9997 3.21436ZM8.9997 12.8572C7.97671 12.8572 6.99564 12.4509 6.27229 11.7275C5.54895 11.0041 5.14255 10.0231 5.14255 9.00007H6.42827C6.42827 9.68209 6.69919 10.3361 7.18148 10.8183C7.66362 11.3006 8.31768 11.5715 8.9997 11.5715V12.8572Z" fill={fill}/>
<path d="M8.35703 0H9.64274V2.57143H8.35703V0Z" fill={fill}/>
<path d="M15.429 8.35718H18.0004V9.64289H15.429V8.35718Z" fill={fill}/>
<path d="M8.35703 15.4286H9.64274V18H8.35703V15.4286Z" fill={fill}/>
<path d="M0 8.35718H2.57143V9.64289H0V8.35718Z" fill={fill}/>
<path d="M2.17877 3.08753L3.08796 2.17834L4.90618 3.99657L3.99699 4.90575L2.17877 3.08753Z" fill={fill}/>
<path d="M13.0929 3.99229L14.9111 2.17407L15.8203 3.08326L14.0021 4.90148L13.0929 3.99229Z" fill={fill}/>
<path d="M13.0966 14.0033L14.0058 13.0941L15.824 14.9123L14.9148 15.8215L13.0966 14.0033Z" fill={fill}/>
<path d="M2.18338 14.9169L4.0016 13.0986L4.91078 14.0078L3.09256 15.826L2.18338 14.9169Z" fill={fill}/>
</svg>

    );
  }
  
  export default TimeIcons; 