

const NavbarItem = (props: { 
    isToggleOpen: boolean, 
    handleUpdateToggleState: Function, 
    index: number, 
    activeClass: string, 
    activeSrc: string, 
    inactiveSrc: string,
    step:string 
}) => {
return (
    <div onClick={() => props.handleUpdateToggleState(props.index)} className={`buliding_item ${props.step} ${props.isToggleOpen && props.activeClass}`}>
        <img src={`/images/svgIcons/${props.isToggleOpen ? props.activeSrc : props.inactiveSrc}.svg`} alt="img"></img>
    </div>
);
}


export default NavbarItem;