import React, { Fragment, useState, useRef } from "react";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { componentHover } from "../../services/componentServices";
import { Component } from "../Types";
import ComponentIconMapData from "./ComponentIconMapData/ComponentIconMapData";
import ComponentMenuHandler from "./ComponentMenuHandler";
import "./ComponentMenuStyle.css";
import WallIcon from "../SvgIcons/WallIcon";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";
import ComponentLogo from "../SvgIcons/ComponentLogo";

const TestingComponent = ({ helpButtonToggle }: any) => {
  const { componentItem, onComponentClick } = ComponentMenuHandler();
  const { componentIconMap } = ComponentIconMapData();

  const isHoverRef = useRef(null);

  const [isHover, setIsHover] = useState(false);
  const [testComponentHover, setTestComponentHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openWallpaper, setOpenWallpaper] = useState(false);
  const [isCurrentComponentHover, setIsCurrentComponentHover] = useState(0);
  const { selectedComponentRoom, currentStateOfComponent } = useTypedSelector(
    ({ component }) => component
  );

  const openComponentByClick = () => {
    setIsOpen((prev) => !prev);
    setOpenWallpaper(false);
  };

  const onComponentHover = (
    componentName: string,
    index: number,
    event: MouseEvent
  ) => {
    event.stopPropagation();
    if (!selectedComponentRoom && componentName) {
      setTimeout(() => {
        componentHover(componentName, "Hovered");
      }, 500);
    }
    setIsHover(true);
    setIsCurrentComponentHover(index);
  };

  const onComponentUnHover = (
    componentName: string,
    index: number,
    event: MouseEvent
  ) => {
    event.stopPropagation();
    if (!selectedComponentRoom && componentName) {
      componentHover(componentName, "Unhovered");
    }
    setIsHover(false);
    setIsCurrentComponentHover(index);
    setTestComponentHover(false);
  };

  const handleWallpaper = () => {
    setOpenWallpaper((prev) => !prev);
  };

  const handleMouseOver = () => {
    if (isHoverRef.current) {
      setTestComponentHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (isHoverRef.current) {
      setTestComponentHover(false);
    }
  };

  return (
    <>
      <div
        className={`left-side-manu-main step step-4 ${
          isOpen ? "active-content" : ""
        }`}
        id="components-child-test"
        style={{ width: "57px" }}
      >
        {testComponentHover && helpButtonToggle && (
          <HelpSection
            text="Edit room elements"
            iconSpace=""
            className="text_component_tooltip"
            icon={<HelpRightIcon />}
          />
        )}
        <div onClick={openComponentByClick} className="component_svg_icon">
          {isOpen ? <ComponentLeftArrow /> : <ComponentRightArrow />}
        </div>
        <ul
          className="components-child-main component_item_height false"
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          ref={isHoverRef}
        >
          <li className="decor-go" onClick={openComponentByClick}>
            {/* <CompanyLogo /> */}
            <ComponentLogo />
            <p className="content-go">DECOR2GO.CA</p>
          </li>

          {componentItem?.RoomType.Components.map(
            (component: Component, index: number) => {
              const componentName = component.Component;
              const componentDisplayName = component.DisplayName;
              const category = component.Category;
              const iconComponent = componentIconMap[componentName];
              return (
                <Fragment key={index}>
                  {category === "Base" && (
                    <li
                      onMouseEnter={(e: any) =>
                        onComponentHover(componentName, index, e)
                      }
                      onMouseLeave={(e: any) =>
                        onComponentUnHover(componentName, index, e)
                      }
                      className={
                        currentStateOfComponent &&
                        selectedComponentRoom === componentName
                          ? "left-icon-main active-bg"
                          : "left-icon-main"
                      } // Removed two classes active-color-bg and active-bg-dropdown
                      onClick={(e) => {
                        onComponentClick(e, componentName);
                      }}
                      data-value={index}
                    >
                      <div className="similer-ic">
                        {React?.cloneElement(iconComponent, {
                          fill:
                            selectedComponentRoom === componentName ||
                            (isHover && index === isCurrentComponentHover)
                              ? "#3A3A3A"
                              : "white",
                        })}
                        <p>{componentDisplayName}</p>
                      </div>
                    </li>
                  )}
                </Fragment>
              );
            }
          )}
          {/* <li onClick={handleWallpaper} className="left-icon-main wall-icon" data-value="3">
            <div className="similer-ic">
              <WallIcon />
              <p>Wall  {openWallpaper ? <WallLeftArrow /> : <WallRightArrow />}</p>
            </div>
            {openWallpaper && 
              <div className="wall-on-hover">
                <ul>
                  <li className="wall">
                    <span>wall 1</span>
                  </li>
                  <li className="wall">
                    <span>wall 2</span>
                  </li>
                  <li className="wall">
                    <span>wall 3</span>
                  </li>
                  <li className="wall">
                    <span>wall 4</span>
                  </li>
                  <li className="wall">
                    <span>wall 5</span>
                  </li>
                  <li className="wall">
                    <span>wall 6</span>
                  </li>
                </ul>
              </div>
            }
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default TestingComponent;

const WallRightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
    >
      <path
        d="M2 15L8.5 8.5L2 2"
        stroke="white"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const WallLeftArrow = () => {
  return (
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none">
      <path
        d="M8.5 15L2 8.5L8.5 2"
        stroke="white"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const ComponentLeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
    >
      <path
        d="M7.6001 14L1.6001 8L7.6001 2"
        stroke="white"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ComponentRightArrow = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3999 14L7.3999 8L1.3999 2"
        stroke="white"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
