import "./TestMobileFooterNavigation.css";
import { useActions } from "../../../hooks/use-actions";
import arrowLeft from "../../../assets/image/arrow-left.png";
import arrowRight from "../../../assets/image/arrow-right.png";
import MobileCameraIndexHandler from "./MobileCameraIndexHandler";
import { useTypedSelector } from "../../../hooks/use-typed-selector";
import { selectedCamera } from "../../../services/footernavbarServices";
import styled from "styled-components";

const EstateMobileSection = styled.div`
  position:relative;
  .mobile_left_arrow{
    left: 26%;
    background: transparent;
    border: none;
    top: 20%;
    // position:absolute;
  }
  .mobile_right_arrow{
    right: 26%;
    top: 20%;
    background: transparent;
    border: none;
    // position:absolute;
  }
  .camera_mobileMode li:first-child{
    margin-left:0px;
  }
  @media only screen and (max-width: 667px){
  .mobile_left_arrow {
    left: 22%;
  }
  .mobile_right_arrow {
    right: 22%;
  }
}
  @media only screen and (max-width: 575px){
    .mobile_left_arrow {
      left: 15%;
    }
    .mobile_right_arrow {
      right: 15%;
    }
  }
  @media only screen and (max-width: 475px){
    .mobile_left_arrow {
      left: 10%;
    }
    .mobile_right_arrow {
      right: 10%;
    }
  }
  @media only screen and (max-width: 375px){
    .mobile_left_arrow {
      left: 5%;
    }
    .mobile_right_arrow {
      right: 5%;
    }
  }
`;

interface SyntheticEvent<T = Element, E = Event> {
  type: string;
  nativeEvent: E;
  persist(): void;
  bubbles: boolean;
  timeStamp: number;
  eventPhase: number;
  isTrusted: boolean;
  cancelable: boolean;
  target: EventTarget;
  preventDefault(): void;
  stopPropagation(): void;
  defaultPrevented: boolean;
  isDefaultPrevented(): boolean;
  currentTarget: EventTarget & T;
  isPropagationStopped(): boolean;
}


interface cameratuype{
  CameraIndex:number
}

const TestMobileFooterNavigation = () => {

    const {numberOfCamerasAvailable} = MobileCameraIndexHandler();

    const { currentActiveRoomtype } = useTypedSelector(({ navbar }) => navbar);
    const {cameraIndex: activeCamera} = useTypedSelector(({ media }) => media);

    const {setCameraIndex } = useActions();

    const handleCamera = (event:SyntheticEvent) => {
        const selectedCam = event.currentTarget.getAttribute('data-cam');
        if (selectedCam) {
            setCameraIndex(parseInt(selectedCam))
            selectedCamera(selectedCam.toString())
        }
        event.stopPropagation();
    }

    // SETUP LATER
    // START
    const handleNextArrowButton = () => {
        if (activeCamera) {
          let newCamera = activeCamera + 1
          if (activeCamera != numberOfCamerasAvailable?.length) {
            setCameraIndex(newCamera)
            selectedCamera(newCamera.toString())
          }
        }
      }
    
      const handlePreviewsArrowButton = () => {
        if (activeCamera) {
          let newCamera = activeCamera - 1
          if (newCamera >= 1) {
            setCameraIndex(newCamera)
            selectedCamera(newCamera.toString())
          }
        }
      }
      // END

    // useEffect(() => {
    //     setCameraIndex(defaultCameraIndexFromBackend?.CameraIndex)
    // }, [defaultCameraIndexFromBackend])

    // !dropdownOpen && SET UP IT LATER 
    return <>
    <EstateMobileSection>
       {currentActiveRoomtype !== "Empty" && (<ul className="camera_mobileMode step step-3">
       <button onClick={handlePreviewsArrowButton} className="mobile_left_arrow"><img src={arrowLeft} /></button>
            {numberOfCamerasAvailable?.map((camera:cameratuype, index:number) => {
                return (
                    <li 
                      key={index} 
                      data-cam={camera.CameraIndex} 
                      onClick={handleCamera} 
                      className={activeCamera ===  camera.CameraIndex ? "camera_ItemMobile1 camera_ItemMobile" : 'camera_ItemMobile1'}>
                    </li>
                );
            }
            )}
            <button onClick={handleNextArrowButton} className="mobile_right_arrow"><img src={arrowRight} /></button>
       </ul>)}
      </EstateMobileSection>
    </>
}



export default TestMobileFooterNavigation;