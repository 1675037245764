import { ActionType } from '../action-types';



export const MobileDropdownOpen = (isOpen: boolean) => {
    return {
        type: ActionType.MOBILE_DROPDOWN_OPEN,
        payload: isOpen
    }
}


export const roofViewToggle = () => {
    return {
        type: ActionType.ROOF_VIEW_TOGGLE,
        // payload: isOpen
    }
}